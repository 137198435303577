import React from "react"
import styles from "./styles.module.scss"
import clsx from "clsx"

type Props = {
  className?: string
}

const OrderLayout: React.FC<Props> = ({children, className}) => {
  return <div className={clsx(styles.orderLayout, className)}>{children}</div>
}

export default OrderLayout
