import React, {useState} from "react"
import styles from "./styles.module.scss"
import ParentModule from "./ParentModule"

type Props = {
  resourcesGroupedByParentName: any
}

const SidebarMenu: React.FC<Props> = ({resourcesGroupedByParentName}) => {
  const [isOpenedSubMenu, setOpenSubMenu] = useState<any>({})

  const toggleSubMenu = (value: string) => {
    return () => setOpenSubMenu({...isOpenedSubMenu, [value]: !isOpenedSubMenu[value]})
  }

  return (
    <ul className={styles.menu}>
      {Object.keys(resourcesGroupedByParentName)
        .sort()
        .map((key, index) => (
          <ParentModule
            key={index}
            subModules={resourcesGroupedByParentName[key]}
            toggleSubMenu={toggleSubMenu(key)}
            isSubMenuOpened={isOpenedSubMenu[key]}
          >
            {key}
          </ParentModule>
        ))}
    </ul>
  )
}

export default SidebarMenu
