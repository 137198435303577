import React from "react"
import {Grid, Paper, Table, TableBody, TableCell, TableRow} from "@mui/material"
import {ChannelReport} from "../../interfaces/salesInterfaces"

type Props = {
  channelsStatistics: ChannelReport[] | null
}

const ChannelsReport: React.FC<Props> = ({channelsStatistics}) => {
  return (
    <div className="mt-2">
      <Grid container spacing={2}>
        {channelsStatistics &&
          channelsStatistics.map((channelReport, index) => (
            <Grid key={index} item sm={3}>
              <div
                style={{borderWidth: "1px", borderRadius: "4px", padding: "1rem", borderColor: "lightgray"}}
                className="flex flex-col"
              >
                <h2 className="font-semibold text-lg">{channelReport.name}</h2>
                <div className="flex justify-between">
                  <h4>Количество городов</h4>
                  <span className="font-semibold">{channelReport.count_cities}</span>
                </div>
                <div className="flex justify-between">
                  <h4>Количество складов</h4>
                  <span className="font-semibold">{channelReport.count_warehouses}</span>
                </div>
                <div className="flex justify-between">
                  <h4>Количество пользователей</h4>
                  <span className="font-semibold">{channelReport.count_users}</span>
                </div>
                <div className="flex justify-between">
                  <h4>Количество товаров</h4>
                  <span className="font-semibold">{channelReport.count_model_stocks}</span>
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default ChannelsReport
