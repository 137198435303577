import React, {useEffect} from "react"
import {useSearchParams} from "react-router-dom"
import {StorageHandler} from "../../app/utils/storageHandler"
import {MicrosoftADService} from "../../app/services/MicrosoftAdService"
import {Alert} from "../../app/utils/alert"
import requestData from "../../app/utils/requestData"
import {User} from "../../app/interfaces/authInterfaces"
import {AuthService} from "../../app/services/AuthService"
import useFetchUserInfo from "../../app/hooks/useFetchUserInfo"
import {useUserContext} from "../../app/context/userContext"

const AdAuthorization = () => {
  const [searchParams] = useSearchParams()
  const {userInfo, updateUserInfo} = useUserContext()

  useEffect(() => {
    if (searchParams.get("code")) {
      verifyToken()
    }
  }, [searchParams])

  const verifyToken = async () => {
    try {
      const tokenVerify = (
        await MicrosoftADService.getTokenFromMicrosoftRedirectURL({
          params: {code: searchParams.get("code")!},
        })
      ).data

      if (tokenVerify.status) {
        await StorageHandler.writeIntoStorage("access_token", tokenVerify.result.access_token)
        await StorageHandler.writeIntoStorage("refresh_token", tokenVerify.result.refresh_token)
        window.location.reload()
      }
    } catch (e) {
      Alert.displayErrorMessage("Произошла ошибка!")
    }
  }

  return <div></div>
}

export default AdAuthorization
