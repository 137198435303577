import React from "react"
import {Resource} from "../../interfaces/authInterfaces"
import styles from "./styles.module.scss"
import {Collapse, Icon} from "@mui/material"
import clsx from "clsx"
import {NavLink} from "react-router-dom"

type Props = {
  subModules: Resource[]
  toggleSubMenu: () => void
  isSubMenuOpened: boolean
}

const ParentModule: React.FC<Props> = React.memo(({children, subModules, toggleSubMenu, isSubMenuOpened}) => {
  return (
    <>
      <li className={styles.menuItem} onClick={toggleSubMenu}>
        {children} <Icon className={clsx(styles.menuIcon, {[styles.expand]: isSubMenuOpened})}>chevron_right</Icon>
      </li>
      <Collapse in={isSubMenuOpened}>
        {subModules.map((module, index) => {
          if (!module.sub_module && module.icon)
            return (
              <li key={index}>
                <NavLink
                  to={`/${module.resource_name}`}
                  className={({isActive}) => clsx(styles.menuItemChild, {[styles.menuItemActive]: isActive})}
                >
                  <Icon>{module.icon}</Icon>
                  {module.description}
                </NavLink>
              </li>
            )
        })}
      </Collapse>
    </>
  )
})

export default ParentModule
