import {useUserContext} from "../context/userContext"
import {matchPath, useLocation, useParams} from "react-router-dom"
import {getUniqueListOfPermissions} from "../utils/helpers"

const useHandlePermissions = (): {hasPermissions: (permissionName: string) => boolean} => {
  const {userInfo} = useUserContext()
  const location = useLocation()
  const params = useParams()
  const currentResource = location.pathname.substring(1)

  const permissions = getUniqueListOfPermissions(userInfo?.roles!!)

  const hasPermissions = (permissionName: string) => {
    if (params) {
      // Значит путь является динамическим
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].permission_name === permissionName && permissions[i].resource) {
          const parsedResource = permissions[i].resource!.resource_name.replaceAll("[", ":").replaceAll("]", "")
          const match = matchPath(parsedResource, location.pathname)
          return !!match
        }
      }
    } else {
      for (let i = 0; i < permissions.length; i++) {
        if (
          permissions[i].permission_name === permissionName &&
          permissions[i].resource?.resource_name === currentResource
        ) {
          return true
        }
      }
    }
    return false
  }
  return {hasPermissions}
}

export default useHandlePermissions
