import {createContext, useContext} from "react"

export const LoadingContext = createContext<{isLoading: boolean; setLoading: (isLoading: boolean) => void}>({
  isLoading: true,
  setLoading: () => {},
})

export const useLoadingContext = () => {
  return useContext(LoadingContext)
}
