import * as yup from "yup"

export const OTPReceiveSchema = yup.object().shape({
  phone: yup.string().test({
    name: "phoneValidation",
    test: (value) => {
      if (!value) return false
      else return value.replace(/_/g, "").length === 18
    },
    message: "Неверный формат номера",
  }),
})

export const OTPVerifySchema = yup.object().shape({
  code: yup.string().length(4),
})
