import {OrderPriceReview} from "../../../../app/interfaces/orderControlnterfaces"
import {ReactNode} from "react"
import {convertNumberInPriceFormat} from "../../../../app/utils/helpers"

const getDiscountSection = (priceReview: OrderPriceReview) => {
  const discountsSection: {key: ReactNode; value: ReactNode}[] = []
  if (priceReview?.discount?.total) {
    if (
      priceReview.discount.promo?.max_discount &&
      priceReview.discount.promo?.discount_type !== "percent" &&
      priceReview.discount.promo.order_discount !== priceReview.discount.promo?.max_discount
    ) {
      let discountValue = convertNumberInPriceFormat(priceReview.discount.promo.order_discount)
      discountValue += ` из ${convertNumberInPriceFormat(priceReview.discount.promo?.max_discount)} тг`
      discountsSection.push({
        key: "Скидка по акциям",
        value: discountValue,
      })
    }
    if (
      priceReview?.discount?.promo?.promo_type_code === "promo_coupon" &&
      priceReview?.discount.promo.order_discount
    ) {
      discountsSection.push({
        key: "Скидка за промокод",
        value: `- ${convertNumberInPriceFormat(priceReview?.discount.promo.order_discount)} тг`,
      })
    }
    if (priceReview?.discount?.trade_in?.trade_in_discount) {
      discountsSection.push({
        key: "Скидка за Trade-In",
        value: `- ${convertNumberInPriceFormat(priceReview.discount?.trade_in?.trade_in_discount)} тг`,
      })
    }
    if (priceReview?.discount?.gift_certificate?.gift_certificate_discount) {
      discountsSection.push({
        key: "Сертификат",
        value: `- ${convertNumberInPriceFormat(priceReview?.discount?.gift_certificate?.gift_certificate_discount)} тг`,
      })
    }
    if (priceReview?.discount?.cashback?.bonuses) {
      discountsSection.push({
        key: "Бонусы",
        value: `- ${convertNumberInPriceFormat(priceReview?.discount?.cashback?.bonuses)} тг`,
      })
    }
    discountsSection.push({
      key: "Общая скидка",
      value: `- ${convertNumberInPriceFormat(priceReview.discount.total)} тг`,
    })
  }
  return discountsSection
}

export default getDiscountSection
