import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios"
import {StorageHandler} from "./storageHandler"
import {AuthService} from "../services/AuthService"
import {LoadingHandler} from "./loading"
import qs from "qs"

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = StorageHandler.getFromStorage("access_token")
  if (config.headers && token && config.headers["Authorization"] === undefined) {
    config.headers["Authorization"] = `Bearer ${token}`
  }
  if (config.params && !config.params.disableLoadingProgressBar) {
    LoadingHandler.showLoading()
  }
  if (config.params && config.params.disableLoadingProgressBar) delete config.params.disableLoadingProgressBar
  if (config.params && config.params.disableAuthorization && config.headers && config.headers["Authorization"]) {
    delete config.params.disableAuthorization
    delete config.headers["Authorization"]
  }
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  LoadingHandler.hideLoading()
  return Promise.reject(error)
}

const onResponse = async (response: AxiosResponse): Promise<AxiosResponse> => {
  LoadingHandler.hideLoading()
  if (response.data?.status_code === 305 && response.data?.message === "Could not validate credentials") {
    const refreshToken = StorageHandler.getFromStorage("refresh_token")
    try {
      if (refreshToken) {
        const authResponseData = await AuthService.renewRefreshToken(refreshToken)
        const authResponse = await authResponseData?.data
        if (authResponse?.status_code === 305 && authResponse?.message === "Could not validate credentials") {
          StorageHandler.removeFromStorage("access_token")
          StorageHandler.removeFromStorage("refresh_token")
        } else if (authResponse.status === true && authResponse.message === "Success") {
          StorageHandler.writeIntoStorage("access_token", authResponse.result?.access_token)
          StorageHandler.writeIntoStorage("refresh_token", authResponse.result?.refresh_token)
          return axiosInstance(response.config)
        }
      }
    } catch (e: any) {
      return response
    }
  }
  return response
}

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  LoadingHandler.hideLoading()
  return Promise.reject(error)
}

const axiosInstance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "repeat"}),
})

axiosInstance.interceptors.request.use(onRequest, onRequestError)
axiosInstance.interceptors.response.use(onResponse, onResponseError)

export default axiosInstance
