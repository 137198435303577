import React, {useEffect, useState} from "react"
import {SaleService} from "../../services/SaleService"
import UsersReport from "./UsersReport"
import {ChannelReport, CityReport, UserReport, WarehouseReport} from "../../interfaces/salesInterfaces"
import ChannelsReport from "./ChannelsReport"
import WarehousesReport from "./WarehousesReport"
import CitiesReport from "./CitiesReport"
import {Grid} from "@mui/material"
import requestData from "../../utils/requestData"

type Props = {}

const ReportContainer: React.FC<Props> = () => {
  const [usersStatistics, setUsersStatistics] = useState<UserReport[] | null>(null)
  const [warehousesStatistics, setWarehousesStatistics] = useState<WarehouseReport[] | null>(null)
  const [channelsStatistics, setChannelsStatistics] = useState<ChannelReport[] | null>(null)
  const [citiesStatistics, setCitiesStatistics] = useState<CityReport[] | null>(null)

  useEffect(() => {
    Promise.all([fetchCitiesReport(), fetchChannelsReport(), fetchUsersReport(), fetchWarehousesReport()])
  }, [])

  const fetchUsersReport = async () => {
    const data = await requestData(SaleService.getUserReport)
    setUsersStatistics(data)
  }

  const fetchWarehousesReport = async () => {
    const data = await requestData(SaleService.getWarehousesReport)
    setWarehousesStatistics(data)
  }

  const fetchChannelsReport = async () => {
    const data = await requestData(SaleService.getChannelsReport)
    setChannelsStatistics(data)
  }

  const fetchCitiesReport = async () => {
    const data = await requestData(SaleService.getCitiesReport)
    setCitiesStatistics(data)
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={12}>
        <h3 className="text-lg font-semibold uppercase">Статистика пользователей</h3>
        <UsersReport usersStatistics={usersStatistics} />
      </Grid>
      <Grid item md={6} sm={12}>
        <h3 className="text-lg font-semibold uppercase">Статистика складов</h3>
        <WarehousesReport warehousesStatistics={warehousesStatistics} />
      </Grid>
      <Grid item md={12}>
        <h3 className="text-lg font-semibold uppercase">Статистика каналов</h3>
        <ChannelsReport channelsStatistics={channelsStatistics} />
      </Grid>
      <Grid item md={12}>
        <h3 className="text-lg font-semibold uppercase">Статистика городов</h3>
        <CitiesReport citiesStatistics={citiesStatistics} />
      </Grid>
    </Grid>
  )
}

export default ReportContainer
