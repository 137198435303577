import React from "react"
import {Button as MuiButton, ButtonBaseProps, ButtonProps} from "@mui/material"

type Props = ButtonProps &
  ButtonBaseProps & {
    disabled?: boolean
    type?: "submit" | "button"
    target?: string
    component?: any
  }

const Button: React.FC<Props> = ({type, disabled, target, children, component, ...otherProps}) => {
  return (
    <MuiButton
      type={type || "button"}
      disabled={disabled}
      variant="contained"
      component={component}
      target={target}
      {...otherProps}
    >
      {children}
    </MuiButton>
  )
}

export default Button
