import React from "react"
import styles from "./styles.module.scss"
import LoginContainer from "./LoginContainer"

const LoginPage: React.FC = () => {
  return (
    <section className={styles.login}>
      <div className={styles.loginLogo}>
        <img src="/img/logo_dark.svg" alt="logo" aria-label="Logo image" />
      </div>
      <h2 className={styles.loginTitle} aria-label="System entrance">
        Вход в систему
      </h2>
      <h3 className={styles.loginSubtitle}>Административная панель Freedom Mobile</h3>
      <LoginContainer />
    </section>
  )
}

export default LoginPage
