import React from "react"
import {UserReport} from "../../interfaces/salesInterfaces"
import {Paper, Table, TableBody, TableCell, TableRow} from "@mui/material"
import {Pie} from "react-chartjs-2"

type Props = {
  usersStatistics: UserReport[] | null
}

const UsersReport: React.FC<Props> = ({usersStatistics}) => {
  const data = {
    labels: ["Количество активных пользователей", "Количество заблокированных пользователей"],
    datasets: [
      {
        data: [
          usersStatistics && usersStatistics?.length > 0 && usersStatistics[0].count_active,
          usersStatistics && usersStatistics?.length > 0 && usersStatistics[0].count_blocked,
        ],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="flex items-center">
      <div style={{width: "400px", height: "400px"}}>
        <Pie options={{maintainAspectRatio: false}} data={data} />
      </div>
      <div className="grow">
        <div className="flex">
          <h3>Общее количество пользователей:</h3>
          <p className="ml-1 font-semibold">
            {usersStatistics && usersStatistics?.length > 0 && usersStatistics[0].count_all}
          </p>
        </div>
        <div className="flex">
          <h3>Количество активных пользователей:</h3>
          <p className="ml-1 font-semibold">
            {usersStatistics && usersStatistics?.length > 0 && usersStatistics[0].count_active}
          </p>
        </div>
        <div className="flex">
          <h3>Количество заблокированных пользователей:</h3>
          <p className="ml-1 font-semibold">
            {usersStatistics && usersStatistics?.length > 0 && usersStatistics[0].count_blocked}
          </p>
        </div>
      </div>
    </div>
  )
}

export default UsersReport
