import React from "react"
import MainLayout from "./MainLayout"

const ErrorLayout: React.FC = () => {
  return (
    <div>
      <h2 className="font-bold text-lg flex justify-center items-center h-screen">Произошла ошибка!</h2>
    </div>
  )
}

export default ErrorLayout
