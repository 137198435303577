import React from "react"
import {Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material"
import CloseIcon from "../../icons/CloseIcon"

type Props = DialogProps & {
  title: string
  handleClose: () => void
  hideDialogActions?: boolean
  action?: {
    onConfirmAction: () => void
    severity: "error" | "info" | "success"
    label: string
    actionIcon?: React.ReactNode
  }
  maxWidth?: string
  open: boolean
  withoutPaddingTop?: boolean
  withCloseIcon?: boolean
}

const Modal: React.FC<Props> = ({
  open,
  handleClose,
  title,
  children,
  maxWidth,
  action,
  hideDialogActions,
  withoutPaddingTop,
  withCloseIcon,
  ...props
}) => {
  return (
    <Dialog maxWidth={maxWidth || "sm"} fullWidth open={open} onClose={handleClose} {...props}>
      {!withCloseIcon && <DialogTitle>{title}</DialogTitle>}
      {withCloseIcon && (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 20px 0 0"}}>
          <DialogTitle>{title}</DialogTitle>
          <div onClick={handleClose} style={{cursor: "pointer"}}>
            <CloseIcon />
          </div>
        </div>
      )}
      <DialogContent sx={{paddingTop: withoutPaddingTop ? "inherit" : "1rem !important"}}>{children}</DialogContent>
      {!hideDialogActions && (
        <DialogActions>
          {action && (
            <Button startIcon={action.actionIcon} onClick={action.onConfirmAction} color={action.severity}>
              {action.label}
            </Button>
          )}
          <Button onClick={handleClose}>Закрыть</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default Modal
