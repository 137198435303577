import React, {useEffect, useState} from "react"
import requestData from "../../app/utils/requestData"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import {Alert} from "../../app/utils/alert"
import {TransferService} from "../../app/services/TransferService"
import {ITransferStatus} from "../../app/interfaces/transferInterfaces"

type Props = {
  transferId: number | null
  isOpen: boolean
  handleClose: () => void
  refetchTransferStatuses: () => void
}

const ChangeTransferStatusModal: React.FC<Props> = ({transferId, isOpen, refetchTransferStatuses, handleClose}) => {
  const [statusList, setStatusList] = useState<ITransferStatus[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [value, setValue] = React.useState<string>("")
  const [query, setQuery] = useState<string>("")
  const radioGroupRef = React.useRef<HTMLElement>(null)
  const results = statusList.filter((item) => item.transfer_status_name.toLowerCase().includes(query.toLowerCase()))
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }
  useEffect(() => {
    if (!isOpen) {
      setValue("")
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) fetchTransferStatuses()
  }, [isOpen])

  const fetchTransferStatuses = async () => {
    const statuses = await requestData<ITransferStatus[]>(TransferService.getAllTransferStatuses)
    if (Array.isArray(statuses)) {
      setStatusList(statuses)
      setIsLoading(false)
    }
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const changeTransferStatus = async () => {
    if (value) {
      if (await requestData(TransferService.changeTransferStatus, {transferId, transferStatusCode: value})) {
        Alert.displaySuccessMessage("Статус перемещения успешно изменен!")
        refetchTransferStatuses()
        handleClose()
      }
    }
  }

  return (
    <Dialog
      sx={{"& .MuiDialog-paper": {width: "80%", maxHeight: 435}}}
      TransitionProps={{onEntering: handleEntering}}
      title="Изменение статуса перемещения"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>Изменить статус заказа</DialogTitle>
      <DialogContent dividers={true}>
        <TextField label="Поиск" size="small" variant="outlined" value={query} onChange={handleChange} fullWidth />
        {isLoading && "Данные загружаются..."}
        {!isLoading && (
          <RadioGroup
            aria-label="order status change"
            name="order-status-change"
            value={value}
            onChange={handleChangeValue}
          >
            {results.map((transferStatus) => (
              <FormControlLabel
                key={transferStatus.transfer_status_code}
                control={<Radio />}
                label={transferStatus.transfer_status_name}
                value={transferStatus.transfer_status_code}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="info" onClick={changeTransferStatus}>
          Изменить
        </Button>
        <Button autoFocus onClick={handleClose}>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeTransferStatusModal
