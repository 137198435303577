import {Alert} from "../../../app/utils/alert"
import {AxiosResponse} from "axios"
import {convertFileIntoBase64} from "../../../app/utils/helpers"
import {MimeTypesToExtensions} from "../../../app/interfaces/layoutInterfaces"
import requestData from "../../../app/utils/requestData"

export const getDateInCurrentTimeZone = (notConvertedDate: string, withoutTimeStamp?: boolean) => {
  const currentDate = withoutTimeStamp ? new Date(notConvertedDate.slice(0, -1)) : new Date(notConvertedDate)
  currentDate.setHours(currentDate.getHours())
  return currentDate
}

export const getDateInPreviewsTimeZone = (notConvertedDate: string) => {
  let currentDate = new Date(notConvertedDate)
  currentDate.setHours(currentDate.getHours() - 5)
  return currentDate
}

export const handleDownloadTemplate =
  (serviceFunc: (...params: any) => Promise<AxiosResponse<ArrayBuffer>>, templateName: string, ...params: any) =>
  () => {
    serviceFunc(...params)
      .then((res) => {
        const str = new TextDecoder().decode(res.data)
        try {
          const bufferCheck = JSON.parse(str)
          if (bufferCheck && bufferCheck.status === false) {
            Alert.displayErrorMessage("Не удалось выгрузить файл")
            return
          }
        } catch (e) {}
        let fileName = templateName ?? "file"
        if (!hasFileExtension(fileName)) {
          const contentType = res.headers["content-type"]
          const extension = getExtensionFromMime(contentType as keyof typeof MimeTypesToExtensions)
          if (extension) fileName += extension
          else fileName += ".xlsx"
        }
        createURLAndDownload(res, fileName)
      })
      .catch((e) => {
        console.log(e)
        Alert.displayErrorMessage("Не удалось выгрузить файл!")
      })
  }

export const handleUploadTemplate =
  (serviceFunc: (data: any) => Promise<AxiosResponse<any>>, onSuccessUpload: () => void) => (data: any) => {
    serviceFunc(data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === "Success") {
            Alert.displaySuccessMessage("Файл был успешно загружен")
            onSuccessUpload()
          } else {
            Alert.displayErrorMessage("Не удалось загрузить файл")
          }
        } else {
          Alert.displayErrorMessage("Произошла ошибка на сервере")
        }
      })
      .catch((e) => Alert.displayErrorMessage("Не удалось загрузить шаблон"))
      .finally()
  }

export const formatDateToMonth = (date: Date | string): string => {
  const dateFormat = new Date(date)
  const year = dateFormat.getFullYear().toString()
  const month = (dateFormat.getMonth() + 1).toString().padStart(2, "0")
  const day = "01"

  return `${year}-${month}-${day}`
}

export const createURLAndDownload = (res: AxiosResponse<any, any>, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([res.data], {
      type: res.data.type,
    }),
  )
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
}

export const downloadByUrl = (url: string, fileName: string) => {
  const link = document.createElement("a")
  link.href = process.env.REACT_APP_BASE_API_URL + "/image" + url
  link.target = "_blank"
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
}

export const getStringMonth = (dateString: string) => {
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ]

  const date = new Date(dateString)
  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const month = months[monthIndex]

  return `Список за ${month} ${year}г`
}

export const getEndOfMonthDate = (inputDate: string): string => {
  const dateParts = inputDate.split("-")
  const year = parseInt(dateParts[0], 10)
  const month = parseInt(dateParts[1], 10)

  if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
    throw new Error("Invalid input date format. Expected format: yyyy-mm-dd")
  }

  const lastDayOfMonth = new Date(year, month, 0).getDate()
  const endOfMonthDate = `${year}-${String(month).padStart(2, "0")}-${String(lastDayOfMonth).padStart(2, "0")}`

  return endOfMonthDate
}

export const formatFineArray = async (files: File[]) => {
  if (!files || !files.length) return
  const formattedArray: ({file_name: string; file_path: string} | null)[] = await Promise.all(
    files.map(async (file) => {
      try {
        const file_path = await convertFileIntoBase64(file)
        return {
          file_name: file.name,
          file_path,
        }
      } catch (error) {
        return null
      }
    }),
  )
  return formattedArray.filter((item) => item !== null)
}

export const getExtensionFromMime = (mime: keyof typeof MimeTypesToExtensions) => {
  return MimeTypesToExtensions[mime]
}

export const hasFileExtension = (filename: string) => {
  const lastPeriodIndex = filename.lastIndexOf(".")
  if (lastPeriodIndex === -1) {
    return false
  }
  const extension = filename.slice(lastPeriodIndex + 1)
  return extension.length > 0
}
