import {BeelineChangePhoneNumber} from "../interfaces/onlineIntegrationInterfaces";
import {PromiseResponse} from "../interfaces/http";
import http from "../utils/http";

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/subscription-rates/api/v1`

export namespace SubscriptionRatesService {
  export const beelineChangePhoneNumber = (data: BeelineChangePhoneNumber): PromiseResponse<any> => {
    return http.post(`${BASE_URL}/beeline/change-phone`, data)
  }

  export const getBeelineIntegrationInfo = (phone: string): PromiseResponse<any> => {
    return http.get(`${BASE_URL}/beeline/tariff-services`, {
      params: {
        phone: phone,
      },
    })
  }

  export const sendOtpTele2 = (phone: number) => {
    return http.get(`${BASE_URL}/tele2/send-otp`, {
      params: {
        phone,
      },
    })
  }

  export const approveOtpTele2 = (phone: string, code: string) => {
    return http.post(`${BASE_URL}/tele2/approve-otp`, {
      phone,
      code,
    })
  }
}