import http from "../utils/http"
import {PromiseResponse} from "../interfaces/http"
import {
  ITransferEvent,
  ITransferFullInfo,
  ITransferHistory,
  ITransferProduct,
  ITransferStatus,
  TransferStatus,
  WarehouseBaseInfo,
} from "../interfaces/transferInterfaces"

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/transfer/api/v1`

const getTransferWarehouse = (orderId: number): PromiseResponse<WarehouseBaseInfo[]> => {
  return http.get(`${BASE_URL}/transfer/warehouse?order_id=${orderId}`)
}

const getTransferFullInfo = (transferId: number): PromiseResponse<ITransferFullInfo> => {
  return http.get(`${BASE_URL}/transfer/full-info?transfer_id=${transferId}`)
}

const getAllTransferStatuses = (): PromiseResponse<ITransferStatus[]> => {
  return http.get(`${BASE_URL}/transfer-status/all`)
}

const getTransferHistory = (transferId: number): PromiseResponse<ITransferHistory[]> => {
  return http.get(`${BASE_URL}/transfer-history?transfer_id=${transferId}`)
}

const changeTransferStatus = ({
  transferId,
  transferStatusCode,
}: {
  transferId: number
  transferStatusCode: keyof typeof TransferStatus
}): PromiseResponse<any> => {
  return http.put(
    `${BASE_URL}/transfer-history/change-status?transfer_id=${transferId}&transfer_status_code=${transferStatusCode}`,
  )
}

const deleteTransferStatus = ({
  transferId,
  transferHistoryId,
}: {
  transferId: number
  transferHistoryId: number
}): PromiseResponse<any> => {
  return http.delete(
    `${BASE_URL}/transfer-history/delete-status?transfer_history_id=${transferHistoryId}&transfer_id=${transferId}`,
  )
}

const getTransferAcceptenceProducts = (transferId: number): PromiseResponse<ITransferProduct[]> => {
  return http.get(`${BASE_URL}/transfer-product/acceptance?transfer_id=${transferId}`)
}

const getTransferEvents = ({
  orderId,
  transferId,
  startDate,
  endDate,
  page,
  size,
  phone,
  userId,
}: {
  orderId: number
  transferId: number
  startDate?: string
  endDate?: string
  page?: number
  size?: number
  phone?: string
  userId?: number
}): PromiseResponse<{
  items: ITransferEvent[]
  page: number
  size: number
  total: number
}> => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/eventlog/api/v1/transfer-event/items?order_id=${orderId}&transfer_id=${transferId}&`
  if (startDate) url += `start_date=${startDate}&`
  if (endDate) url += `end_date=${endDate}&`
  if (page) url += `page=${page}&`
  if (size) url += `size=${size}&`
  if (phone) url += `phone=${phone}&`
  if (userId) url += `user_id=${userId}&`
  return http.get(url)
}

export const TransferService = {
  getTransferWarehouse,
  getTransferFullInfo,
  getTransferHistory,
  getAllTransferStatuses,
  changeTransferStatus,
  deleteTransferStatus,
  getTransferAcceptenceProducts,
  getTransferEvents,
}
