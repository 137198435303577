import React, {useEffect, useState} from "react"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {OrderStatusFromOneC} from "../../../app/interfaces/orderControlnterfaces"
import {Divider, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material"
import requestData from "../../../app/utils/requestData"
import {OrderControlService} from "../../../app/services/OrderControlService"

type Props = {
  orderId: number
}

const OrderInfoFromOneC: React.FC<Props> = ({orderId}) => {
  const [orderStatusInfoFromOneC, setOrderStatusFromOneC] = useState<OrderStatusFromOneC | null>(null)

  const fetchOrderStatusFromOneC = async (orderId: number) => {
    const orderStatusInfo = await requestData<OrderStatusFromOneC>(OrderControlService.getOrderStatusFrom1c, orderId)
    if (orderStatusInfo) {
      setOrderStatusFromOneC(orderStatusInfo)
    }
  }

  useEffect(() => {
    fetchOrderStatusFromOneC(orderId)
  }, [])

  return (
    <OrderLayout>
      <OrderLayoutHeading>Информация о заказе в 1С</OrderLayoutHeading>

      {orderStatusInfoFromOneC && (
        <div className={styles.customerInfo}>
          <div className={styles.products}>
            <h4 className={styles.heading}>Информация о заказе</h4>
          </div>
          <div className={styles.orderLayoutItem}>
            <p>Дата заказа</p>{" "}
            <span>
              {orderStatusInfoFromOneC.order?.order_date
                ? new Date(orderStatusInfoFromOneC.order?.order_date).toLocaleString()
                : "-"}
            </span>
          </div>

          <div className={styles.orderLayoutItem}>
            <p>Номер заказа</p> <span>{orderStatusInfoFromOneC.order?.order_number || "-"}</span>
          </div>

          <div className={styles.orderLayoutItem}>
            <p>Статус заказа</p> <span>{orderStatusInfoFromOneC.order?.order_status || "-"}</span>
          </div>

          {orderStatusInfoFromOneC.order?.products && orderStatusInfoFromOneC.order?.products.length > 0 && (
            <>
              <h5 className="font-medium mt-1">Товары в заказе</h5>
              <Table component={Paper} sx={{marginTop: "0.5rem"}}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>СКУ товара</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>QTY</TableCell>
                    <TableCell>Цена</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderStatusInfoFromOneC.order?.products?.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{product.sku}</TableCell>
                      <TableCell>{product.amount}</TableCell>
                      <TableCell>{product.qty}</TableCell>
                      <TableCell>{product.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}

          <Divider sx={{marginTop: "1rem"}} />

          <div className={styles.products}>
            <h4 className={styles.heading}>Информация о чеке</h4>
          </div>
          <div className={styles.orderLayoutItem}>
            <p>ID магазина</p> <span>{orderStatusInfoFromOneC.order?.order_store_id || "-"}</span>
          </div>

          <div className={styles.orderLayoutItem}>
            <p>Дата выписки чека</p>{" "}
            <span>
              {orderStatusInfoFromOneC.receipt?.receipt_date
                ? new Date(orderStatusInfoFromOneC.receipt?.receipt_date).toLocaleString()
                : "-"}
            </span>
          </div>

          <div className={styles.orderLayoutItem}>
            <p>Номер чека</p> <span>{orderStatusInfoFromOneC.receipt?.receipt_number || "-"}</span>
          </div>

          <div className={styles.orderLayoutItem}>
            <p>Статус чека</p> <span>{orderStatusInfoFromOneC.receipt?.receipt_status || "-"}</span>
          </div>

          {orderStatusInfoFromOneC.receipt?.payment_methods &&
            orderStatusInfoFromOneC.receipt?.payment_methods.length > 0 && (
              <>
                <h5 className="font-medium mt-1">Способы оплаты в чеке</h5>
                <Table component={Paper} sx={{marginTop: "0.5rem"}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Количество</TableCell>
                      <TableCell>Код терминала</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderStatusInfoFromOneC.receipt?.payment_methods?.map((paymentMethod, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{paymentMethod.amount}</TableCell>
                        <TableCell>{paymentMethod.terminal_code}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
        </div>
      )}
    </OrderLayout>
  )
}

export default OrderInfoFromOneC
