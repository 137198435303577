const showLoading = () => {
  const loadingContainer = document.getElementById("preloader-container")
  loadingContainer?.classList.remove("hidden-preloader")
}

const hideLoading = () => {
  const loadingContainer = document.getElementById("preloader-container")
  loadingContainer?.classList.add("hidden-preloader")
}

export const LoadingHandler = {
  showLoading,
  hideLoading,
}
