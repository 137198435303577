import http from "../utils/http"
import {
  OperationCreate,
  Permission,
  PermissionCreate,
  PermissionRoleCreate,
  ResourceCreate,
  RoleCreate,
  User,
  UserRoleCreate,
} from "../interfaces/authInterfaces"
import {ITableResponse, PromiseResponse} from "../interfaces/http"

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/authorization/api/v1`
const NOTIFICATION_BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/notification/api/v1`

const getUserList = (page: number, size: number, filterData?: object, disableLoadingProgressBar?: boolean) => {
  return http.get(`${BASE_URL}/users/items`, {
    params: {page, size, disableLoadingProgressBar, ...filterData},
  })
}
const getUsers = (search_line?: string): PromiseResponse<ITableResponse<User[]>> => {
  return http.get(`${BASE_URL}/users/items`, {
    params: {
      search_line: search_line,
      page: 1,
      size: 25,
    },
  })
}

const getAllUsers = (): PromiseResponse<User[]> => {
  return http.get(`${BASE_URL}/users/all`)
}

const getUserInfo = (userId: string | number) => {
  return http.get(`${BASE_URL}/users`, {params: {user_id: userId}})
}

const blockUser = (phone: string) => {
  return http.post(`${BASE_URL}/users/block`, null, {params: {phone}})
}

const unblockUser = (phone: string) => {
  return http.post(`${BASE_URL}/users/unlock`, null, {params: {phone}})
}

const changePhoneUser = (phone: string) => {
  return http.post(`${BASE_URL}/users/change-phone`)
}

const changeChannel = (data: {channel_id: number}, userId: number) => {
  return http.put(`${BASE_URL}/users/channel`, data, {params: {user_id: userId}})
}

const getOperationList = (page: number, size: number) => {
  return http.get(`${BASE_URL}/operation/items`, {params: {page, size}})
}

const getAllOperations = () => {
  return http.get(`${BASE_URL}/operation/all`)
}

const getResourceList = (page: number, size: number) => {
  return http.get(`${BASE_URL}/resource/items`, {params: {page, size}})
}

const getAllResources = () => {
  return http.get(`${BASE_URL}/resource/all`)
}

const getAllPermissions = () => {
  return http.get(`${BASE_URL}/permission/all`)
}

const getPermissionList = (page: number, size: number) => {
  return http.get(`${BASE_URL}/permission/items`, {params: {page, size}})
}

const getMissingPermissionList = (roleId: number) => {
  return http.get(`${BASE_URL}/permission/missing-permission`, {
    params: {
      role_id: roleId,
    },
  })
}

const getPermission = (permissionId: number) => {
  return http.get(`${BASE_URL}/permission`, {params: {permission_id: permissionId}})
}

const getRoleList = (page: number, size: number) => {
  return http.get(`${BASE_URL}/role/items`, {params: {page, size}})
}

const getAllRoles = () => {
  return http.get(`${BASE_URL}/role/all`)
}

const getRole = (roleId: number) => {
  return http.get(`${BASE_URL}/role`, {params: {role_id: roleId}})
}

const createRole = (data: RoleCreate) => {
  return http.post(`${BASE_URL}/role`, data)
}

const createRolePermission = (data: PermissionRoleCreate) => {
  return http.post(`${BASE_URL}/permission-role`, data)
}

const deleteRolePermission = (data: PermissionRoleCreate) => {
  return http.delete(`${BASE_URL}/permission-role`, {data})
}

const createUserRole = (data: UserRoleCreate) => {
  return http.post(`${BASE_URL}/user-role`, data)
}

const deleteUserRole = (data: UserRoleCreate) => {
  return http.delete(`${BASE_URL}/user-role`, {data})
}

const updateRole = (data: RoleCreate) => {
  return http.put(`${BASE_URL}/role`, data, {
    params: {
      role_id: data.role_id,
    },
  })
}

const deleteRole = (roleId: number) => {
  return http.delete(`${BASE_URL}/role`, {params: {role_id: roleId}})
}

const createPermission = (data: PermissionCreate) => {
  return http.post(`${BASE_URL}/permission`, data)
}

const updatePermission = (data: Permission) => {
  return http.put(`${BASE_URL}/permission`, data, {
    params: {
      permission_id: data.permission_id,
    },
  })
}

const deletePermission = (permissionId: number) => {
  return http.delete(`${BASE_URL}/permission`, {
    params: {
      permission_id: permissionId,
    },
  })
}

const createResource = (data: ResourceCreate) => {
  return http.post(`${BASE_URL}/resource`, data)
}

const createOperation = (data: OperationCreate) => {
  return http.post(`${BASE_URL}/operation`, data)
}

const getOtpCodes = (page: number, size: number, filterData?: object) => {
  return http.get(`${NOTIFICATION_BASE_URL}/sms/list`, {params: {page, size, ...filterData}})
}

export const UsersService = {
  getUserList,
  getUsers,
  getAllUsers,
  getUserInfo,
  blockUser,
  unblockUser,
  changePhoneUser,
  changeChannel,
  getAllResources,
  getAllRoles,
  getAllOperations,
  getAllPermissions,
  getRole,
  getPermission,
  getOperationList,
  getResourceList,
  getPermissionList,
  getMissingPermissionList,
  getRoleList,
  getOtpCodes,
  createUserRole,
  createRolePermission,
  deleteRolePermission,
  deleteUserRole,
  updateRole,
  deleteRole,
  createOperation,
  createPermission,
  updatePermission,
  deletePermission,
  createResource,
  createRole,
}
