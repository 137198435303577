import React from "react"
import {UseFormReturn} from "react-hook-form"
import {OtpVerifyModel} from "../../app/interfaces/authInterfaces"
import FormGroup from "../../app/components/Form/FormGroup"
import FormLabel from "../../app/components/Form/FormLabel"
import PinInput from "../../app/components/PinInput/PinInput"
import Button from "../../app/components/Button/Button"
import FormHelperMessage from "../../app/components/Form/FormHelperMessage"
import LoginSendOTPAgain from "./LoginSendOTPAgain"
import Alert from "../../app/components/Alert/Alert"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import styles from "./styles.module.scss"

type Props = {
  form: UseFormReturn<OtpVerifyModel>
  onSubmit: (data: OtpVerifyModel) => void
  sendVerificationCodeAgain: () => Promise<boolean>
  goBack: () => void
}

const LoginOTPVerify: React.FC<Props> = ({form, onSubmit, sendVerificationCodeAgain, goBack}) => {
  const {formState, register, handleSubmit, control} = form
  const {errors, isSubmitting} = formState

  return (
    <div>
      <div className={styles.loginAlert}>
        <Button onClick={goBack} size="small" variant="outlined" startIcon={<ArrowBackIcon />}>
          Вернуться назад
        </Button>
      </div>
      <div className={styles.loginAlert}>
        <Alert closeButton>На указанный Вами номер телефона был отправлен 4-х значный код</Alert>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>Введите код из СМС</FormLabel>
          <PinInput name={{...register("code")}?.name} codeLength={4} control={control} />
          <FormHelperMessage>{errors.code?.message}</FormHelperMessage>
        </FormGroup>
        <LoginSendOTPAgain useAnotherPhone={goBack} sendVerificationCodeAgain={sendVerificationCodeAgain} />
        <Button disabled={isSubmitting} type="submit">
          Проверить код
        </Button>
      </form>
    </div>
  )
}

export default LoginOTPVerify
