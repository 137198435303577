import React from "react"
import Button from "../../../app/components/Button/Button"
import LockIcon from "@mui/icons-material/Lock"
import requestData from "../../../app/utils/requestData"
import {OrderControlService} from "../../../app/services/OrderControlService"
import {Alert} from "../../../app/utils/alert"

type Props = {
  orderId: number
  validationSkipped: boolean
  fetchServicesAgain: () => void
}

const OrderScoringLimitControl: React.FC<Props> = ({orderId, validationSkipped, fetchServicesAgain}) => {
  const changeValidation = (state: boolean) => async () => {
    const response = await requestData(OrderControlService.changeScoringLimitValidation, orderId, state)
    if (response) {
      state
        ? Alert.displaySuccessMessage("Валидация кол-ва попыток скорингов выключена")
        : Alert.displaySuccessMessage("Валидация кол-ва попыток скорингов включена")
      fetchServicesAgain()
    }
  }

  return (
    <div className="my-2.5 flex items-center">
      Статус валидации попыток скоринга: &nbsp;{" "}
      {validationSkipped ? (
        <span className="text-red-500 font-semibold">Отключена</span>
      ) : (
        <span className="text-green-600 font-semibold">Включена</span>
      )}
      {validationSkipped ? (
        <Button
          className="!ml-2"
          startIcon={<LockIcon />}
          color="info"
          size="small"
          variant="outlined"
          onClick={changeValidation(false)}
        >
          Включить
        </Button>
      ) : (
        <Button
          className="!ml-2"
          startIcon={<LockIcon />}
          color="warning"
          size="small"
          variant="outlined"
          onClick={changeValidation(true)}
        >
          Выключить
        </Button>
      )}
    </div>
  )
}

export default OrderScoringLimitControl
