import React, {useEffect, useState} from "react"
import {
  IOrderAction,
  OrderAdditionalInfo,
  OrderCustomerInfo,
  OrderDeliveryInfo,
  OrderDetail,
  OrderHistory,
  OrderPaymentInfo,
  OrderProduct,
  OrderService,
  OrderShipmentInfo,
  OrderShipmentSupplierInfo,
} from "../../../app/interfaces/salesInterfaces"
import Heading from "../../../app/components/Heading/Heading"
import {Grid, Tab, Tabs} from "@mui/material"
import OrderCustomerInfoLayout from "./OrderCustomerInfoLayout"
import OrderProductsLayout from "./OrderProductsLayout"
import OrderPaymentLayout from "./OrderPaymentLayout"
import OrderHistoryInfo from "./OrderHistoryInfo"
import OrderAdditionalInfoLayout from "./OrderAdditionalInfoLayout"
import OrderDeliveryLayout from "./OrderDeliveryLayout"
import OrderServicesInfo from "./OrderServicesInfo"
import OrderShipmentSupplierCustomerInfoLayout from "./OrderShipmentSupplierCustomerInfoLayout"
import Button from "../../../app/components/Button/Button"
import {Alert} from "../../../app/utils/alert"
import {SaleService} from "../../../app/services/SaleService"
import CancelOrderConfirm from "../CancelOrderConfirm"
import OrderShipmentsMap from "./OrderShipmentsMap"
import requestData from "../../../app/utils/requestData"
import useHandlePermissions from "../../../app/hooks/useHandlePermissions"
import ChangeOrderStatusModal from "../ChangeOrderStatusModal"
import {CheckBoxSharp, ModeOutlined, NotInterested, Replay} from "@mui/icons-material"
import {KaspiOrderStatus} from "../../../app/interfaces/kaspiMarketplaceIntegrationInterfaces"
import {KaspiMarketplaceIntegrationService} from "../../../app/services/KaspiMarketplaceIntergationService"
import {KaspiOrderFinish} from "./KaspiOrderFinish"
import OrderInfoFromOneC from "./OrderInfoFromOneC"
import {OrderControlService} from "../../../app/services/OrderControlService"
import {OrderEvent} from "../../../app/interfaces/eventLogInterfaces"
import OrderEventInfo from "./OrderEventInfo"
import LockIcon from "@mui/icons-material/Lock"
import OrderBonusesTab from "./OrderBonusesTab"
import OrderTransfers from "./OrderTransfers"
import {OrderPriceReview} from "../../../app/interfaces/orderControlnterfaces"
import OrderChangeSellerAndChannel from "./OrderChangeSellerAndChannel"
import OrderActionsInfo from "./OrderActionsInfo"
import OrderScoringLimitControl from "./OrderScoringLimitControl"

type Props = {
  order: OrderDetail
  customerInfo: OrderCustomerInfo | null
  orderProducts: OrderProduct[] | null
  orderServices: OrderService[] | null
  orderHistory: OrderHistory[] | null
  orderEvent: OrderEvent[] | null
  orderActions: IOrderAction[]
  orderPaymentInfo: OrderPaymentInfo | null
  orderAdditionalInfo: OrderAdditionalInfo | null
  orderDeliveryInfo: OrderDeliveryInfo | null
  orderShipmentSupplierInfo: OrderShipmentSupplierInfo | null
  orderShipmentMap: OrderShipmentInfo | null
  priceReview: OrderPriceReview | null
  fetchServicesAgain: () => void
}

const OrderIndexContainer: React.FC<Props> = ({
  order,
  orderServices,
  customerInfo,
  orderProducts,
  orderHistory,
  orderActions,
  orderPaymentInfo,
  orderAdditionalInfo,
  orderDeliveryInfo,
  orderShipmentSupplierInfo,
  orderShipmentMap,
  fetchServicesAgain,
  orderEvent,
  priceReview,
}) => {
  const [activeSection, setActiveSection] = useState(1)
  const [kaspiOrder, setKaspiOrder] = useState<KaspiOrderStatus | null>(null)
  const [isOpenedCancelConfirmModal, setIsOpenedCancelConfirmModal] = useState<boolean>(false)
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState<boolean>(false)
  const [confirmationText, setConfirmationText] = useState("")
  const [reserved, setReserved] = useState(false)
  const {hasPermissions} = useHandlePermissions()
  const [orderStatus, setOrderStatus] = useState<OrderHistory[] | null>(orderHistory)
  const deleteOrderStatus = async (orderSession: string, orderHistoryId: number) => {
    const deleteStatus = await requestData<any>(SaleService.deleteOrderStatus, orderSession, orderHistoryId)
    if (deleteStatus && orderStatus) {
      setOrderStatus(orderStatus?.filter((item) => item.order_history_id !== orderHistoryId))
    }
  }
  const [reservationTimeout, setReservationTimeout] = useState(false)

  useEffect(() => {
    setOrderStatus(orderHistory)
  }, [orderHistory])

  const checkOrderIsFromKaspi = () => {
    return orderAdditionalInfo?.channel.code === "KASPI"
  }

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveSection(newValue)
  }

  const checkNeedToFinishKaspiOrder = () => {
    if (orderDeliveryInfo?.delivery_type.delivery_type_code === "delivery") {
      const waitingCompletitionFromMarketplace = orderHistory?.find(
        (item) => item.order_status_code === "waiting_completion_in_marketplace",
      )
      const completedInMarketplace = orderHistory?.find((item) => item.order_status_code === "completed_in_marketplace")

      if (waitingCompletitionFromMarketplace && !completedInMarketplace) {
        return true
      } else if (completedInMarketplace) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  useEffect(() => {
    if (checkOrderIsFromKaspi()) fetchKaspiOrderInfo()
  }, [orderAdditionalInfo])

  const fetchKaspiOrderInfo = async () => {
    const kaspiOrder = await requestData<KaspiOrderStatus>(
      KaspiMarketplaceIntegrationService.getStatusOfKaspiOrder,
      order.order_id,
    )
    if (kaspiOrder) setKaspiOrder(kaspiOrder)
  }

  const openCancelModal = () => {
    setConfirmationText("Вы действительно хотите отменить заказ?")
    setIsOpenedCancelConfirmModal(true)
  }

  const openReservationModal = () => {
    setConfirmationText("Вы действительно хотите зарезервировать?")
    setIsOpenedCancelConfirmModal(true)
  }

  const openRefundPayboxModal = () => {
    setConfirmationText("Вы действительно хотите сделать возврат средств?")
    setIsOpenedCancelConfirmModal(true)
  }

  const openSyncModal = () => {
    setConfirmationText("Вы действительно хотите засинхронизировать заказ с 1с?")
    setIsOpenedCancelConfirmModal(true)
  }
  const closeCancelModal = () => setIsOpenedCancelConfirmModal(false)
  const openChangeStatusModal = () => setIsChangeStatusModalOpen(true)
  const closeChangeStatusModal = () => setIsChangeStatusModalOpen(false)

  const cancelOrder = async () => {
    const cancelOrderRequest = await requestData(OrderControlService.cancelOrder, order.order_session)
    if (cancelOrderRequest) {
      Alert.displaySuccessMessage("Заказ был успешно отменен!")
      closeCancelModal()
      fetchServicesAgain()
    }
  }

  const refundPayboxMoney = async () => {
    const refundRequest = await requestData(SaleService.refundPayboxMoney, order.order_session)
    if (refundRequest) {
      Alert.displaySuccessMessage("Вы успешно вернули средства за заказ!")
      closeCancelModal()
      fetchServicesAgain()
    }
  }

  const reservationProduct = async () => {
    // На 5 секунд блокируем кнопку, дабы сохранить идемпотентность на бэке
    setReservationTimeout(true)
    setTimeout(() => {
      setReservationTimeout(false)
    }, 5000)

    const reservationRequest = await requestData(OrderControlService.postReservationStatus, order.order_id)
    if (reservationRequest) {
      Alert.displaySuccessMessage("Товар успешно зарезервирован!")
      setReserved(true)
      closeCancelModal()
      fetchServicesAgain()
    }
  }

  const syncOrderOneC = async () => {
    const reservationRequest = await requestData(OrderControlService.syncOrderOneC, order.order_id)
    if (reservationRequest) {
      Alert.displaySuccessMessage("Заказ успешно засинхронизирован 1c!")
      setReserved(true)
      closeCancelModal()
      fetchServicesAgain()
    }
  }

  const checkCanCancelOrder = (): boolean => {
    if (!hasPermissions("cancel_order")) return false
    let canCancelOrder = true
    orderHistory?.forEach((historyItem) => {
      if (historyItem.order_status_code === "one_c_complete_success") {
        canCancelOrder = false
      } else if (historyItem.order_status_code === "broker_cancel_success") {
        canCancelOrder = false
      }
    })
    return canCancelOrder
  }
  let confirmAction
  if (confirmationText === "Вы действительно хотите отменить заказ?") {
    confirmAction = cancelOrder
  } else if (confirmationText === "Вы действительно хотите сделать возврат средств?") {
    confirmAction = refundPayboxMoney
  } else if (confirmationText === "Вы действительно хотите засинхронизировать заказ с 1с?") {
    confirmAction = syncOrderOneC
  } else {
    confirmAction = reservationProduct
  }

  return (
    <>
      <Heading>Информация о заказе</Heading>
      <h2 className="font-semibold text-lg mb-2.5 uppercase">Order id: {order.order_id}</h2>
      {order?.order_type?.order_type_code === "exchange" && (
        <div className="p-2 bg-amber-500 w-fit rounded-md text-white">
          Данный заказ был создан в качестве обмена на другой заказ
        </div>
      )}

      <div className="my-2">
        <Tabs onChange={changeTab} value={activeSection}>
          <Tab label="Основная информация" value={1} />
          <Tab label="Информация о заказе с 1с" value={2} />
          <Tab label="Бонусы по заказу" value={3} />
          <Tab label="Информация по перемещению" value={4} />
          <Tab label="Смена канала и продавцов" value={5} />
        </Tabs>
      </div>

      {checkOrderIsFromKaspi() ? (
        <div>
          <div className="font-medium text-md text-orange-500">Данный заказ был сделан в магазине Kaspi.kz</div>
          <KaspiOrderFinish
            fetchKaspiOrderInformationAgain={fetchKaspiOrderInfo}
            kaspiOrderStatus={kaspiOrder}
            ableToFinish={checkNeedToFinishKaspiOrder()}
          />
        </div>
      ) : null}

      <Button
        startIcon={<NotInterested />}
        onClick={openCancelModal}
        variant="contained"
        color="error"
        className="!mb-3"
        disabled={!checkCanCancelOrder()}
      >
        Отменить заказ
      </Button>
      {orderStatus?.some((item) => item.order_status_code === "one_c_complete_success") ? null : (
        <Button
          startIcon={<CheckBoxSharp />}
          onClick={openReservationModal}
          variant="contained"
          color="info"
          className="!mb-3 !ml-3"
          disabled={!hasPermissions("cancel_order") || reserved || reservationTimeout}
        >
          Принудительная резервация
        </Button>
      )}
      <Button
        startIcon={<ModeOutlined />}
        onClick={openChangeStatusModal}
        variant="contained"
        color="warning"
        className="!mb-3 !ml-3"
        disabled={!hasPermissions("cancel_order")}
      >
        Изменить статус заказа
      </Button>
      <Button
        startIcon={<Replay />}
        onClick={openRefundPayboxModal}
        color="error"
        className="!mb-3 !ml-3"
        disabled={!checkCanCancelOrder()}
      >
        Сделать возврат средств PayBox
      </Button>
      <Button
        startIcon={<Replay />}
        onClick={openSyncModal}
        color="error"
        className="!mb-3 !ml-3"
        disabled={!checkCanCancelOrder()}
      >
        Синхронизировать с 1с
      </Button>

      <div className="my-2.5 flex items-center">
        Статус валидации Apple: &nbsp;{" "}
        {order.skip_apple_validation ? (
          <span className="text-red-500 font-semibold">Отключена</span>
        ) : (
          <span className="text-green-600 font-semibold">Включена</span>
        )}
        {order.skip_apple_validation ? (
          <Button
            className="!ml-2"
            startIcon={<LockIcon />}
            color="info"
            size="small"
            variant="outlined"
            onClick={async () => {
              if (await requestData(OrderControlService.updateStatusOfAppleValidation, order.order_id, false)) {
                Alert.displaySuccessMessage("Валидация Apple включена!")
                fetchServicesAgain()
              }
            }}
          >
            Включить
          </Button>
        ) : (
          <Button
            className="!ml-2"
            startIcon={<LockIcon />}
            color="warning"
            size="small"
            variant="outlined"
            onClick={async () => {
              if (await requestData(OrderControlService.updateStatusOfAppleValidation, order.order_id, true)) {
                Alert.displaySuccessMessage("Валидация Apple отключена!")
                fetchServicesAgain()
              }
            }}
          >
            Выключить
          </Button>
        )}
      </div>

      <OrderScoringLimitControl
        orderId={order.order_id}
        fetchServicesAgain={fetchServicesAgain}
        validationSkipped={order.skip_validation_scoring}
      />

      {activeSection === 1 && (
        <Grid container spacing={1}>
          <Grid item md={8} sm={12}>
            {customerInfo && <OrderCustomerInfoLayout customerInfo={customerInfo} />}
            {orderShipmentSupplierInfo && (
              <OrderShipmentSupplierCustomerInfoLayout
                supplierShipmentInfo={orderShipmentSupplierInfo}
                orderSession={order.order_session}
              />
            )}
            {orderProducts && <OrderProductsLayout products={orderProducts} />}
            {orderServices && (
              <OrderServicesInfo
                orderServices={orderServices}
                refetchServices={fetchServicesAgain}
                orderHasFinished={!!orderStatus?.some((item) => item.order_status_code === "one_c_complete_success")}
              />
            )}
            {orderDeliveryInfo && <OrderDeliveryLayout deliveryInfo={orderDeliveryInfo} />}
            {orderShipmentMap && <OrderShipmentsMap shipmentsMap={orderShipmentMap} />}
            {orderAdditionalInfo && (
              <OrderAdditionalInfoLayout
                additionalInfo={orderAdditionalInfo}
                orderId={order.order_id}
                kaspiOrderInfo={kaspiOrder}
              />
            )}
          </Grid>
          <Grid item md={4} sm={12}>
            {orderPaymentInfo && (
              <OrderPaymentLayout
                payment={orderPaymentInfo}
                priceReview={priceReview}
                orderSession={order.order_session}
              />
            )}
            {orderHistory && (
              <OrderHistoryInfo orderStatus={orderStatus} deleteOrderStatus={deleteOrderStatus} order={order} />
            )}
            {<OrderEventInfo orderEvent={orderEvent} />}
            {orderActions && orderActions.length > 0 ? <OrderActionsInfo orderActions={orderActions} /> : null}
          </Grid>
        </Grid>
      )}
      {activeSection === 2 && <OrderInfoFromOneC orderId={order.order_id} />}
      {activeSection === 3 && <OrderBonusesTab orderId={order.order_id} />}
      {activeSection === 4 && <OrderTransfers orderId={order.order_id} />}
      {activeSection === 5 && (
        <OrderChangeSellerAndChannel orderId={order.order_id} fetchOrderAgain={fetchServicesAgain} />
      )}

      <CancelOrderConfirm
        isOpen={isOpenedCancelConfirmModal}
        handleClose={closeCancelModal}
        cancelOrder={confirmAction}
        confirmationText={confirmationText}
      />
      <ChangeOrderStatusModal
        orderSession={order.order_session}
        isOpen={isChangeStatusModalOpen}
        handleClose={closeChangeStatusModal}
        fetchOrderAgain={fetchServicesAgain}
      />
    </>
  )
}

export {OrderIndexContainer}
