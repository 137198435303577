import React, {useState} from "react"
import {OrderAdditionalInfo} from "../../../app/interfaces/salesInterfaces"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {TextField} from "@mui/material"
import Button from "../../../app/components/Button/Button"
import requestData from "../../../app/utils/requestData"
import {SaleService} from "../../../app/services/SaleService"
import {Alert} from "../../../app/utils/alert"
import {KaspiOrderStatus} from "../../../app/interfaces/kaspiMarketplaceIntegrationInterfaces"

type Props = {
  additionalInfo: OrderAdditionalInfo
  orderId: number
  kaspiOrderInfo: KaspiOrderStatus | null
}

const OrderAdditionalInfoLayout: React.FC<Props> = ({additionalInfo, orderId, kaspiOrderInfo}) => {
  const [comment, setComment] = useState(additionalInfo.order_comment || "")

  const changeComment = async () => {
    if (await requestData(SaleService.updateOrderComment, comment, additionalInfo.order_session)) {
      Alert.displaySuccessMessage("Комментарий к заказу был изменен!")
    }
  }

  return (
    <OrderLayout>
      <OrderLayoutHeading>Дополнительная информация</OrderLayoutHeading>
      <div className={styles.customerInfo}>
        <div className={styles.orderLayoutItem}>
          <p>ID заказа (Order ID)</p> <span>{orderId}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Номер кредитного контракта</p> <span>{additionalInfo.broker_credit_contract || "-"}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Номер чека с 1С</p> <span>{additionalInfo.one_c_order || "-"}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Дата подписания</p> <span>-</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Click ID: </p> <span>{additionalInfo?.click_id || "-"}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>UTM Source: </p> <span>{additionalInfo?.utm_code || "-"}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Канал продажи</p> <span>{additionalInfo.channel.name}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Город</p> <span>{additionalInfo.city?.city_name_ru}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>ОТП код, использованный для подписи контракта</p>{" "}
          <span>{additionalInfo.sms_messages.map((item) => item.otp)}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Order session</p> <span>{additionalInfo.order_session || "-"}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Номер страхования</p> <span>{additionalInfo.insurance_code || "-"}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>ИИН покупателя</p> <span>{additionalInfo.customer_iin || "-"}</span>
        </div>
        {kaspiOrderInfo && kaspiOrderInfo.kaspi_order_code && (
          <div className={styles.orderLayoutItem}>
            <p>Ссылка на каспи заказ</p>
            <a
              href={`https://kaspi.kz/mc/#/orders/${kaspiOrderInfo.kaspi_order_code}`}
              target="_blank"
              rel="noopener noreferer"
              className={styles.kaspiLink}
            >
              Ссылка на каспи заказ
            </a>
          </div>
        )}
        <div className={styles.orderLayoutItem}>
          <p>Комментарии к заказу</p>
          <div style={{display: "flex", width: "50%"}}>
            <TextField
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              size="small"
              variant="filled"
              placeholder="Комментарий"
              label="Комментарий к заказу"
              sx={{flexGrow: 1}}
            />
            <Button onClick={changeComment} sx={{marginLeft: "0.5rem"}} color="warning" variant="text" size="small">
              Изменить
            </Button>
          </div>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Текущий статус</p> <span>{additionalInfo?.order_status?.order_status_name}</span>
        </div>
      </div>
    </OrderLayout>
  )
}

export default OrderAdditionalInfoLayout
