import React from "react"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {getDateInCurrentTimeZone} from "../../motivation/utils/utils"
import {IOrderAction} from "../../../app/interfaces/salesInterfaces"

type Props = {
  orderActions: IOrderAction[]
}

const OrderActionsInfo: React.FC<Props> = ({orderActions}) => {
  return (
    <OrderLayout>
      <OrderLayoutHeading>Действия в заказе</OrderLayoutHeading>
      <div className={styles.orderHistoryBody}>
        {orderActions ? (
          orderActions.map((item, index) => (
            <React.Fragment key={index}>
              <div key={item.event_id} className={styles.item}>
                <div className={styles.line}>
                  <div className={styles.count}>{index + 1}</div>
                </div>
                <div className={styles.info}>
                  <div className={styles.infoHeading}>{item.event_message}</div>
                  <span>{getDateInCurrentTimeZone(item.created_at).toLocaleString()}</span>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className={styles.infoHeading}>Данные не найдены</div>
        )}
      </div>
    </OrderLayout>
  )
}

export default OrderActionsInfo
