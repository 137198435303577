import http from "../utils/http"
import {OtpReceiveModel, OtpVerifyModel} from "../interfaces/authInterfaces"
import axios from "axios"
import {PromiseResponse} from "../interfaces/http"

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/authorization/api/v1`
const BASE_NEW_URL = `${process.env.REACT_APP_BASE_API_URL}/authorization/api/v2`

const receiveOtp = (data: OtpReceiveModel) => {
  return http.post(`${BASE_URL}/users/sign-in/send`, data)
}

const verifyOtp = (data: OtpVerifyModel & {phone: string}) => {
  return http.post(`${BASE_NEW_URL}/users/sign-in/check`, data)
}

const renewRefreshToken = (refreshToken: string) => {
  return axios.post(`${BASE_NEW_URL}/users/refresh-token`, null, {headers: {Authorization: `Bearer ${refreshToken}`}})
}

const fetchUserInfo = () => {
  return http.get(`${BASE_URL}/users/me`)
}

const checkTokenForRedirectingOffline = (phone: string): PromiseResponse<{token: string}> => {
  return http.post(`${BASE_URL}/users/token-ar`, null, {
    params: {
      phone,
    },
  })
}

export const AuthService = {
  receiveOtp,
  verifyOtp,
  renewRefreshToken,
  fetchUserInfo,
  checkTokenForRedirectingOffline,
}
