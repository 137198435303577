import React, {Dispatch, SetStateAction, useState} from "react"
import styles from "./styles.module.scss"
import {Delete, ModeOutlined} from "@mui/icons-material"
import {ITransferHistory} from "../../../app/interfaces/transferInterfaces"
import Button from "../../../app/components/Button/Button"
import {getDateInCurrentTimeZone} from "../../motivation/utils/utils"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import {CheckCircleIcon} from "../../../app/icons/CheckCircleIcon"
import ChangeTransferStatusModal from "../ChangeTransferStatusModal"
import requestData from "../../../app/utils/requestData"
import {TransferService} from "../../../app/services/TransferService"

type Props = {
  history: ITransferHistory[]
  transferId: number | null
  fetchHistoryAndEvents: () => void
  title: string
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}

const OrderTransferHistory: React.FC<Props> = ({
  history,
  transferId,
  loading,
  setLoading,
  fetchHistoryAndEvents,
  title,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const removeHistoryStatus = async (transferHistoryId: number) => {
    setLoading(true)
    await requestData(TransferService.deleteTransferStatus, {transferHistoryId, transferId}).then(() => {
      fetchHistoryAndEvents()
    })
    setLoading(false)
  }

  return (
    <>
      <OrderLayout>
        <div className={styles.orderHistoryHeader}>
          <OrderLayoutHeading>История перемещений для склада {title}</OrderLayoutHeading>
          <Button
            startIcon={<ModeOutlined />}
            onClick={() => setIsModalOpen(true)}
            variant="contained"
            color="warning"
            className="!mb-3 !ml-3"
            disabled={loading}
          >
            Изменить статус перемещения
          </Button>
        </div>
        {loading && "Данные загружаются..."}
        {!loading && (
          <div className={styles.orderHistoryBody}>
            {history.map((item) => (
              <React.Fragment key={item.transfer_history_id}>
                <div className={styles.item}>
                  <div className={styles.checkedIconContainer}>
                    <CheckCircleIcon />
                    <div className={styles.iconLine} />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.infoHeading}>
                      {item.transfer_status.transfer_status_name}
                      <Button
                        onClick={() => removeHistoryStatus(item.transfer_history_id)}
                        color="info"
                        className={styles.infoButton}
                        disabled={loading}
                      >
                        <Delete />
                      </Button>
                    </div>
                    <span>{getDateInCurrentTimeZone(item.created_at, true).toLocaleString()}</span>
                    {item.transfer_history_description && (
                      <span style={{color: "#929415"}}>{item.transfer_history_description}</span>
                    )}
                    {item.last_modified_user_name && (
                      <span style={{color: "#80997d"}}>Изменил: {item.last_modified_user_name}</span>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </OrderLayout>
      <ChangeTransferStatusModal
        transferId={transferId}
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        refetchTransferStatuses={fetchHistoryAndEvents}
      />
    </>
  )
}

export default OrderTransferHistory
