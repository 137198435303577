import React, {useEffect, useState} from "react"
import {User} from "../interfaces/authInterfaces"
import {AuthService} from "../services/AuthService"
import {StorageHandler} from "../utils/storageHandler"
import requestData from "../utils/requestData"

const useFetchUserInfo = (toggleLoading?: any) => {
  const [user, setUser] = useState<User | null | undefined>(undefined)

  const updateUserInfo = (user: User | null) => {
    setUser(user)
  }

  useEffect(() => {
    const accessToken = StorageHandler.getFromStorage("access_token")
    if (accessToken) {
      fetchUserInfo()
    } else toggleLoading(false)
  }, [])

  const fetchUserInfo = async () => {
    const authRequest = await requestData<User>(AuthService.fetchUserInfo)
    if (authRequest) {
      updateUserInfo(authRequest)
      toggleLoading(false)
    } else {
      StorageHandler.removeFromStorage("access_token")
      StorageHandler.removeFromStorage("refresh_token")
      updateUserInfo(null)
    }
  }

  return {userInfo: user, updateUserInfo, fetchUserInfo}
}

export default useFetchUserInfo
