import React from "react"
import styles from "./styles.module.scss"
import clsx from "clsx"

const Preloader: React.FC = () => {
  return (
    <div className={styles.overlay}>
      <div className={clsx(styles.spinner, styles.center)}>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
        <div className={styles.spinnerBlade}></div>
      </div>
    </div>
  )
}

export default Preloader
