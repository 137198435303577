import React from "react"
import {OrderDetail, OrderHistory} from "../../../app/interfaces/salesInterfaces"
import styles from "./styles.module.scss"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import {getDateInCurrentTimeZone} from "../../motivation/utils/utils"
import {Delete} from "@mui/icons-material"
import Button from "../../../app/components/Button/Button"

type Props = {
  order: OrderDetail
  deleteOrderStatus: (orderSession: string, orderHistoryId: number) => Promise<void>
  orderStatus: OrderHistory[] | null
}

const OrderHistoryInfo: React.FC<Props> = ({order, deleteOrderStatus, orderStatus}) => {
  return (
    <OrderLayout>
      <OrderLayoutHeading>История заказа</OrderLayoutHeading>
      <div className={styles.orderHistoryBody}>
        {orderStatus &&
          orderStatus.map((item, index) => (
            <React.Fragment key={index}>
              <div key={item.order_history_id} className={styles.item}>
                <div className={styles.line}>
                  <div className={styles.count} style={{background: `${item.order_status_color}`}}>
                    {index + 1}
                  </div>
                </div>
                <div className={styles.info}>
                  <div className={styles.infoHeading}>
                    {item.order_status_name}
                    <Button
                      onClick={() => deleteOrderStatus(order.order_session, item.order_history_id)}
                      color="info"
                      className={styles.infoButton}
                    >
                      <Delete />
                    </Button>
                  </div>
                  <span>{getDateInCurrentTimeZone(item.created_at).toLocaleString()}</span>
                  {item.order_history_description && (
                    <span style={{color: "#929415"}}>{item.order_history_description}</span>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
      </div>
    </OrderLayout>
  )
}

export default OrderHistoryInfo
