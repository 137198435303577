import React, {FC, Fragment} from "react"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {getDateInCurrentTimeZone} from "../../motivation/utils/utils"
import {ITransferEvent} from "../../../app/interfaces/transferInterfaces"
import Button from "../../../app/components/Button/Button"

type Props = {
  events: ITransferEvent[]
  loading: boolean
  fetchAllEvents: () => void
  total: number | null
}

const OrderTransferEventInfo: FC<Props> = ({events, total, loading, fetchAllEvents}) => {
  return (
    <OrderLayout className={styles.orderTransferEventInfo}>
      <OrderLayoutHeading>Действия в перемещениях</OrderLayoutHeading>
      {loading && "Данные загружаются..."}
      {!loading && (
        <div className={styles.orderHistoryBody}>
          {!!events.length ? (
            <>
              {events.map((item, index) => (
                <Fragment key={item.id}>
                  <div className={styles.item}>
                    <div className={styles.line}>
                      <div className={styles.count}>{index + 1}</div>
                    </div>
                    <div className={styles.info}>
                      <div className={styles.infoHeading}>{item.event_message}</div>
                      <span>{getDateInCurrentTimeZone(item.created_at).toLocaleString()}</span>
                    </div>
                  </div>
                </Fragment>
              ))}
              {total && total > events.length && (
                <Button className={styles.loadMoreBtn} onClick={() => fetchAllEvents()} disabled={loading}>
                  Загрузить все действия
                </Button>
              )}
            </>
          ) : (
            <div className={styles.infoHeading}>Данные не найдены</div>
          )}
        </div>
      )}
    </OrderLayout>
  )
}

export default OrderTransferEventInfo
