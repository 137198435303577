import React from "react"
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import {AppRoutes} from "../constants/routes"
import LoginPage from "../../pages/login"
import AdAuthorization from "../../pages/login/AdAuthorization"

const GuestRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoutes.home} element={<LoginPage />} />
        <Route path="/microsoft-auth" element={<AdAuthorization />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default GuestRouter
