import React from "react"
import s from "./FMTeamModal.module.scss"

const FMTeamModal = () => {
  return (
    <div className={s.root}>
      <img src="/fm.jpg" alt="" />
    </div>
  )
}

export default FMTeamModal
