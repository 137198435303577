const writeIntoStorage = (key: string, value: any) => {
  localStorage.setItem(key, value)
}

const getFromStorage = (key: string) => {
  return localStorage.getItem(key)
}

const removeFromStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const StorageHandler = {
  writeIntoStorage,
  getFromStorage,
  removeFromStorage,
}
