import React from "react"
import {FormGroup as MuiFormGroup} from "@mui/material"
import styles from "./styles.module.scss"
import clsx from "clsx"

type Props = {
  className?: string
}

const FormGroup: React.FC<Props> = ({children, className}) => {
  return <MuiFormGroup className={clsx(styles.formGroup, className)}>{children}</MuiFormGroup>
}

export default FormGroup
