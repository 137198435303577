import React from "react"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {OrderProduct, ReservationWarehouses} from "../../../app/interfaces/salesInterfaces"
import {Divider} from "@mui/material"
import {convertNumberInPriceFormat} from "../../../app/utils/helpers"

type Props = {
  products: OrderProduct[]
}

const OrderProductsLayout: React.FC<Props> = ({products}) => {
  return (
    <OrderLayout>
      <OrderLayoutHeading>Товар</OrderLayoutHeading>
      {products.map((product, index) => (
        <div key={index} className={styles.products}>
          <h4 className={styles.heading}>{product.model_stock.model_stock_name}</h4>
          <div className={styles.list}>
            <div>
              Количество: <span>{product.quantity}</span>
            </div>
            <div>
              Код товара: <span>{product.sku}</span>
            </div>
            <div>
              Склад: <span>{ReservationWarehouses[product.reservation_warehouse]}</span>
            </div>
            <div>
              Стоимость: <span>{convertNumberInPriceFormat(product.price)}</span>
            </div>
            {!!product.discount && (
              <div>
                Скидка: <span>{convertNumberInPriceFormat(product.discount)}</span>
              </div>
            )}
            <div>
              СКУ: <span>{product.sku}</span>
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </OrderLayout>
  )
}

export default OrderProductsLayout
