import React from "react"
import Modal from "../../app/components/Modal/Modal"

type Props = {
  isOpen: boolean
  handleClose: () => void
  cancelOrder: () => void
  confirmationText: string
}

const CancelOrderConfirm: React.FC<Props> = ({isOpen, handleClose, cancelOrder, confirmationText}) => {
  return (
    <Modal
      title="Подтвердите свое действие"
      open={isOpen}
      handleClose={handleClose}
      action={{
        label: "Подтвердить",
        onConfirmAction: cancelOrder,
        severity: "error",
      }}
    >
      {confirmationText}
    </Modal>
  )
}

export default CancelOrderConfirm
