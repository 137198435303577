import React from "react"
import styles from "./styles.module.scss"

type Props = {}

const Heading: React.FC = ({children}) => {
  return <h2 className={styles.heading}>{children}</h2>
}

export default Heading
