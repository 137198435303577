import {toast} from "react-toastify"

const displaySuccessMessage = (message: string) => {
  toast.success(message, {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss: false,
  })
}

const displayWarningMessage = (message: string) => {
  toast.warning(message, {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss: false,
  })
}

const displayErrorMessage = (error: string) => {
  toast.error(error, {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss: false,
  })
}

export const Alert = {
  displaySuccessMessage,
  displayWarningMessage,
  displayErrorMessage,
}
