import React from "react"
import {TextField, TextFieldProps} from "@mui/material"
import {Control} from "react-hook-form"
import {Controller} from "react-hook-form"

const InputMask = require("react-input-mask")

type Props = Omit<TextFieldProps, "error"> & {
  label: string
  error?: string | undefined
  type?: string
  mask?: "phone" | "hex-color"
  control: Control<any>
  name: string
  className?: string
  disabled?: boolean
  maxLength?: number
}

const MaskWrapper = ({inputRef, mask, value, onChange, ...other}: any) => {
  return <InputMask mask={mask} value={value} onChange={onChange} {...other} />
}

const Input: React.FC<Props> = ({name, label, error, type, mask, control, disabled, maxLength, ...otherProps}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {value, onBlur, onChange, ref}}) => (
        <TextField
          placeholder={mask === "phone" ? "+7 (___) ___ __ __" : otherProps.placeholder}
          label={label}
          variant="filled"
          size="small"
          error={!!error}
          helperText={error}
          fullWidth
          type={type || "text"}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          disabled={disabled}
          InputProps={
            mask
              ? {
                  inputComponent: MaskWrapper,
                  inputProps: {
                    mask: mask === "phone" ? "+7 (999) 999 99 99" : mask === "hex-color" ? "#******" : "",
                    value: value,
                    onChange,
                  },
                }
              : undefined
          }
          inputProps={{
            maxLength: maxLength ? maxLength : undefined, // Задает максимальное количество символов равным 100
          }}
          InputLabelProps={mask === "phone" ? {shrink: true} : {shrink: value === 0 || value}}
          {...otherProps}
        />
      )}
    />
  )
}

export default Input
