import React, {FC, useEffect, useState} from "react"
import OrderLayoutHeading from "./OrderLayoutHeading"
import OrderLayout from "./OrderLayout"
import {Autocomplete as MuiAutocomplete, Grid, TextField} from "@mui/material"
import {RelationService} from "../../../app/services/RelationService"
import {Channel} from "../../../app/interfaces/relationsInterfaces"
import requestData from "../../../app/utils/requestData"
import {convertArrayToSelectOptions} from "../../../app/utils/helpers"
import Button from "../../../app/components/Button/Button"
import {Alert} from "../../../app/utils/alert"
import {SaleService} from "../../../app/services/SaleService"
import {UsersService} from "../../../app/services/UsersService"
import {User} from "../../../app/interfaces/authInterfaces"
import {ITableResponse} from "../../../app/interfaces/http"

type Props = {
  orderId: number
  fetchOrderAgain: () => void
}
const OrderChangeSellerAndChannel: FC<Props> = ({orderId, fetchOrderAgain}) => {
  const [channels, setChannels] = useState<Channel[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [sellers, setSellers] = useState<User[]>([])

  const [selectedChannel, setSelectedChannel] = useState<{label: string; value: number} | null>(null)
  const [commentForChannel, setCommentForChannel] = useState<string>("")
  const [channelLoading, setChannelLoading] = useState<boolean>(false)

  const [selectedSeller, setSelectedSeller] = useState<{label: string; value: number} | null>(null)
  const [commentForSeller, setCommentForSeller] = useState<string>("")
  const [sellerLoading, setSellerLoading] = useState<boolean>(false)

  const [selectedShipment, setSelectedShipment] = useState<{label: string; value: number} | null>(null)
  const [commentForShipment, setCommentForShipment] = useState<string>("")
  const [shipmentLoading, setShipmentLoading] = useState<boolean>(false)

  useEffect(() => {
    Promise.all([fetchListOfChannels(), fetchUsersList(), fetchSellersList()])
  }, [])

  const fetchListOfChannels = async () => {
    const channels = await requestData(RelationService.getAllChannels)
    if (channels) {
      setChannels(channels)
    }
  }

  const fetchUsersList = async (searchLine?: string) => {
    const response = await requestData<ITableResponse<User[]>>(UsersService.getUsers, searchLine)
    if (response) {
      setUsers(response.items)
    }
  }
  const fetchSellersList = async (searchLine?: string) => {
    const response = await requestData<ITableResponse<User[]>>(UsersService.getUsers, searchLine)
    if (response) {
      setSellers(response.items)
    }
  }

  const onInputChangeSeller = (value: string) => {
    fetchUsersList(value)
  }

  const onInputChangeShipment = (value: string) => {
    fetchSellersList(value)
  }

  const changeChannel = async () => {
    let submit = true
    if (commentForChannel.trim().length === 0) {
      Alert.displayErrorMessage("Заполните комментарий!")
      submit = false
    }
    if (!selectedChannel) {
      Alert.displayErrorMessage("Выберите канал")
      submit = false
    }
    if (submit) {
      setChannelLoading(true)
      console.log(selectedChannel)
      const data = {
        code: "channel_id",
        value: selectedChannel && selectedChannel.value,
        comment: commentForChannel,
      }
      const response = await requestData(SaleService.changeOrderChannelAndSeller, orderId, data)
      if (response) {
        Alert.displaySuccessMessage("Вы успешно поменяли канал")
        await fetchOrderAgain()
        setSelectedChannel(null)
        setCommentForChannel("")
      }
      setChannelLoading(false)
    }
  }

  const changeSeller = async () => {
    let submit = true
    if (commentForSeller.trim().length === 0) {
      Alert.displayErrorMessage("Заполните комментарий!")
      submit = false
    }
    if (!selectedSeller) {
      Alert.displayErrorMessage("Выберите продавца")
      submit = false
    }
    if (submit) {
      setSellerLoading(true)
      const data = {
        code: "supplier_id",
        value: selectedSeller && selectedSeller.value,
        comment: commentForSeller,
      }
      const response = await requestData(SaleService.changeOrderChannelAndSeller, orderId, data)
      if (response) {
        Alert.displaySuccessMessage("Вы успешно поменяли продавца")
        await fetchOrderAgain()
        setSelectedSeller(null)
        setCommentForSeller("")
      }
      setSellerLoading(false)
    }
  }

  const changeChipment = async () => {
    let submit = true
    if (commentForShipment.trim().length === 0) {
      Alert.displayErrorMessage("Заполните комментарий!")
      submit = false
    }
    if (!selectedShipment) {
      Alert.displayErrorMessage("Выберите продавца")
      submit = false
    }
    if (submit) {
      setShipmentLoading(true)
      const data = {
        code: "shipment_user_id",
        value: selectedShipment && selectedShipment.value,
        comment: commentForShipment,
      }
      const response = await requestData(SaleService.changeOrderChannelAndSeller, orderId, data)
      if (response) {
        Alert.displaySuccessMessage("Вы успешно поменяли продавца")
        await fetchOrderAgain()
        setSelectedShipment(null)
        setCommentForShipment("")
      }
      setShipmentLoading(false)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={8}>
        <OrderLayout>
          <OrderLayoutHeading>Смена канала</OrderLayoutHeading>
          <MuiAutocomplete
            noOptionsText="Совпадении не найдено"
            fullWidth
            sx={{marginTop: "8px"}}
            value={selectedChannel}
            defaultValue={selectedChannel}
            options={convertArrayToSelectOptions(channels || [], "name", "channel_id") || []}
            onChange={(event, data) => setSelectedChannel(data ? data : null)}
            renderInput={(params) => <TextField {...params} label={"Выберите канал"} size="small" />}
          />
          <TextField
            label={"Комментарий"}
            fullWidth
            size="small"
            rows={4}
            multiline
            value={commentForChannel}
            onChange={(e) => setCommentForChannel(e.target.value)}
            sx={{marginTop: "16px"}}
          />
          <Button
            fullWidth
            sx={{marginTop: "16px"}}
            onClick={() => changeChannel()}
            type={"button"}
            disabled={channelLoading}
          >
            Сменить канал
          </Button>
        </OrderLayout>
      </Grid>
      <Grid item md={8}>
        <OrderLayout>
          <OrderLayoutHeading>Смена продавца</OrderLayoutHeading>
          <MuiAutocomplete
            noOptionsText="Совпадении не найдено"
            fullWidth
            sx={{marginTop: "8px"}}
            value={selectedSeller}
            defaultValue={selectedSeller}
            options={convertArrayToSelectOptions(users || [], "full_name", "user_id") || []}
            onChange={(event, data) => setSelectedSeller(data ? data : null)}
            onInputChange={(event, data) => onInputChangeSeller(data)}
            renderInput={(params) => <TextField {...params} label={"Выберите продавца"} size="small" />}
          />
          <TextField
            label={"Комментарий"}
            fullWidth
            size="small"
            rows={4}
            multiline
            value={commentForSeller}
            onChange={(e) => setCommentForSeller(e.target.value)}
            sx={{marginTop: "16px"}}
          />
          <Button
            fullWidth
            sx={{marginTop: "16px"}}
            onClick={() => changeSeller()}
            type={"button"}
            disabled={sellerLoading}
          >
            Сменить продавца
          </Button>
        </OrderLayout>
      </Grid>
      <Grid item md={8}>
        <OrderLayout>
          <OrderLayoutHeading>Смена продавца, выдавшего товар</OrderLayoutHeading>
          <MuiAutocomplete
            noOptionsText="Совпадении не найдено"
            fullWidth
            sx={{marginTop: "8px"}}
            value={selectedShipment}
            defaultValue={selectedShipment}
            options={convertArrayToSelectOptions(sellers || [], "full_name", "user_id") || []}
            onChange={(event, data) => setSelectedShipment(data ? data : null)}
            onInputChange={(event, data) => onInputChangeShipment(data)}
            renderInput={(params) => <TextField {...params} label={"Выберите продавца"} size="small" />}
          />
          <TextField
            label={"Комментарий"}
            fullWidth
            size="small"
            rows={4}
            multiline
            value={commentForShipment}
            onChange={(e) => setCommentForShipment(e.target.value)}
            sx={{marginTop: "16px"}}
          />
          <Button
            fullWidth
            sx={{marginTop: "16px"}}
            onClick={() => changeChipment()}
            type={"button"}
            disabled={shipmentLoading}
          >
            Сменить продавца
          </Button>
        </OrderLayout>
      </Grid>
    </Grid>
  )
}
export default OrderChangeSellerAndChannel
