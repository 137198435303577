import React from "react"

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <h3>Страница не найдена!</h3>
    </div>
  )
}

export default NotFoundPage
