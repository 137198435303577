import React from "react"
import styles from "./styles.module.scss"
import {Drawer, Icon} from "@mui/material"
import SideBarAvatar from "./SideBarAvatar"
import {useUserContext} from "../../context/userContext"
import {StorageHandler} from "../../utils/storageHandler"
import SidebarMenu from "./SideBarMenu"
import {NavLink} from "react-router-dom"
import {
  filterPermissionsByOperationName,
  getListOfAvailablePermissions,
  getUniqueListOfMenuItems,
  getUniqueListOfResources,
  groupByObjectByObjectKey,
} from "../../utils/helpers"
import {MicrosoftADService} from "../../services/MicrosoftAdService"

const ListItem: React.FC<{url: string; icon: string | undefined; label: string}> = ({label, url, icon}) => {
  return (
    <li className={styles.menuItem}>
      <NavLink to={url} className="flex justify-between w-full">
        {label}
        <Icon>{icon}</Icon>
      </NavLink>
    </li>
  )
}

type Props = {
  isDrawerOpen: boolean
  closeDrawer: () => void
}

const SideBar: React.FC<Props> = ({isDrawerOpen, closeDrawer}) => {
  const {userInfo, updateUserInfo} = useUserContext()

  const signOut = () => {
    StorageHandler.removeFromStorage("access_token")
    StorageHandler.removeFromStorage("refresh_token")
    window.location.replace(MicrosoftADService.logout())
  }

  return (
    <nav className={styles.nav} id="back-to-top-anchor">
      <Drawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#F0F0F0",
            width: "240px",
            boxSizing: "border-box",
          },
        }}
      >
        <div className={styles.drawer}>
          <div className={styles.menuAvatar}>
            <SideBarAvatar fullName={userInfo?.full_name || ""} />
          </div>
          <SidebarMenu
            resourcesGroupedByParentName={getUniqueListOfMenuItems(
              groupByObjectByObjectKey(
                getUniqueListOfResources(
                  filterPermissionsByOperationName(getListOfAvailablePermissions(userInfo?.roles!!), "read"),
                ),
                "parent_name",
              ),
            )}
          />
          <ul className={styles.bottomMenu} onClick={signOut}>
            <ListItem url="" label="Выйти" icon="exit_to_app">
              Выйти
            </ListItem>
          </ul>
        </div>
      </Drawer>
    </nav>
  )
}

export default SideBar
