import React from "react"

type Props = {}

const CheckCircleIcon: React.FC<Props> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.747 9.41506 20.7188 6.93684 18.891 5.10901C17.0632 3.28118 14.5849 2.25299 12 2.25ZM16.6436 10.2927L11.1431 15.5427C11.0032 15.6758 10.8176 15.75 10.6245 15.75C10.4315 15.75 10.2458 15.6758 10.106 15.5427L7.35645 12.9177C7.2128 12.7801 7.12965 12.5911 7.12524 12.3923C7.12084 12.1934 7.19555 12.0009 7.33297 11.8571C7.47038 11.7133 7.65927 11.6299 7.85813 11.6252C8.05699 11.6206 8.24956 11.695 8.39356 11.8323L10.6245 13.9629L15.6064 9.20728C15.7504 9.07004 15.943 8.99558 16.1419 9.00023C16.3407 9.00489 16.5296 9.08828 16.667 9.2321C16.8045 9.37592 16.8792 9.56841 16.8748 9.76727C16.8704 9.96614 16.7872 10.1551 16.6436 10.2927Z"
        fill="#4DB84E"
      />
    </svg>
  )
}

export {CheckCircleIcon}
