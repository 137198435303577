import React, {useEffect, useState} from "react"
import {KaspiOrderApproveOtp, KaspiOrderStatus} from "../../../app/interfaces/kaspiMarketplaceIntegrationInterfaces"
import Button from "../../../app/components/Button/Button"
import {DoneAll, MobileFriendly} from "@mui/icons-material"
import {TextField} from "@mui/material"
import {useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {KaspiOTPValidationSchema} from "../../../app/validation/onlineIntegrationValidation"
import requestData from "../../../app/utils/requestData"
import {KaspiMarketplaceIntegrationService} from "../../../app/services/KaspiMarketplaceIntergationService"
import {Alert} from "../../../app/utils/alert"

type Props = {
  kaspiOrderStatus: KaspiOrderStatus | null
  fetchKaspiOrderInformationAgain: () => void
  ableToFinish: boolean
}

const KaspiOrderFinish: React.FC<Props> = ({kaspiOrderStatus, fetchKaspiOrderInformationAgain, ableToFinish}) => {
  const [showOtpForm, setShowOtpForm] = useState<boolean>(false)
  const {formState, handleSubmit, setValue, reset} = useForm<KaspiOrderApproveOtp>({
    mode: "onSubmit",
    resolver: yupResolver(KaspiOTPValidationSchema),
  })
  const {errors, isSubmitting} = formState

  const onClickFinishOrder = async () => {
    if (await requestData(KaspiMarketplaceIntegrationService.sendOtpKaspi, kaspiOrderStatus?.fmobile_order_id)) {
      setShowOtpForm(true)
    } else {
      Alert.displayErrorMessage("Не удалось отправить OTP код на указанный заказ!")
      setShowOtpForm(false)
    }
  }

  const onSubmitApproveForm = async (data: KaspiOrderApproveOtp) => {
    if (
      await requestData(
        KaspiMarketplaceIntegrationService.approveOtpKaspi,
        kaspiOrderStatus?.fmobile_order_id,
        data.otp_code.toString(),
      )
    ) {
      Alert.displaySuccessMessage("Заказ на Kaspi был успешно завершен!")
      setShowOtpForm(false)
      fetchKaspiOrderInformationAgain()
    }
  }

  useEffect(() => {
    if (!showOtpForm) {
      reset()
    }
  }, [showOtpForm])

  return (
    <div>
      <Button
        sx={{
          margin: "10px 0",
        }}
        startIcon={<DoneAll />}
        color="info"
        variant="text"
        onClick={onClickFinishOrder}
        disabled={!kaspiOrderStatus || !kaspiOrderStatus.is_otp_needed || showOtpForm || !ableToFinish}
      >
        {!kaspiOrderStatus
          ? "Информация о заказе загружается..."
          : kaspiOrderStatus.is_otp_needed
          ? "Завершить заказ на Kaspi"
          : "Заказ уже завершен на Kaspi.kz"}
      </Button>

      {showOtpForm ? (
        <div className="">
          <h3 className="font-medium mb-1">
            Для того, чтобы завершить заказ на Kaspi, <span className="italic">вам нужно получить OTP код клиента</span>
          </h3>
          <p className="opacity-70 mb-1.5">
            Код уже был только что отправлен ему, <span className="italic">введите пожалуйста код ниже</span>
          </p>
          <form onSubmit={handleSubmit(onSubmitApproveForm)} className="mb-2 flex">
            <TextField
              variant="filled"
              label="Код от клиента"
              error={!!errors.otp_code?.message}
              helperText={errors.otp_code?.message}
              onChange={(event) => {
                setValue("otp_code", event.target.value)
              }}
            />
            <Button
              disabled={isSubmitting}
              type="submit"
              startIcon={<MobileFriendly />}
              sx={{marginLeft: "0.5rem"}}
              variant="text"
              size="medium"
              color="success"
            >
              Проверить код и завершить заказ
            </Button>
          </form>
        </div>
      ) : null}
    </div>
  )
}

export {KaspiOrderFinish}
