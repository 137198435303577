import React, {useEffect, useRef, useState} from "react"
import requestData from "../../../app/utils/requestData"
import {ITransferEvent, ITransferHistory, WarehouseBaseInfo} from "../../../app/interfaces/transferInterfaces"
import {TransferService} from "../../../app/services/TransferService"
import {CircularProgress, Grid} from "@mui/material"
import OrderTransferHistory from "./OrderTransferHistory"
import styles from "./styles.module.scss"
import clsx from "clsx"
import OrderTransferProducts from "./OrderTransferProducts"
import OrderTransferDetails from "./OrderTransferDetails"
import OrderTransferEventInfo from "./OrderTransferEventInfo"

type Props = {
  orderId: number
}

const OrderTransfers: React.FC<Props> = ({orderId}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [warehouseList, setWarehouseList] = useState<WarehouseBaseInfo[]>([])
  const [history, setHistory] = useState<ITransferHistory[]>([])
  const [events, setEvents] = useState<ITransferEvent[]>([])
  const eventsTotal = useRef<number | null>(null)
  // const [warehouseList, setWarehouseList] = useState<WarehouseBaseInfo[]>([{transfer_id: 350, warehouse_name: 'test'}, {transfer_id: 355, warehouse_name: 'test2'}, {transfer_id: 360, warehouse_name: 'test3'}]);
  const [activeTransferId, setActiveTransferId] = useState<number | null>(warehouseList[0]?.transfer_id)

  useEffect(() => {
    const fetchWarehouses = async () => {
      setLoading(true)
      const warehouses = await requestData<WarehouseBaseInfo[] | boolean>(TransferService.getTransferWarehouse, orderId)
      if (!Array.isArray(warehouses)) return
      setWarehouseList(warehouses)
      setActiveTransferId(warehouses[0]?.transfer_id)
      setLoading(false)
    }
    fetchWarehouses()
  }, [orderId])

  useEffect(() => {
    if (activeTransferId) {
      fetchHistoryAndEvents(3)
    }
  }, [activeTransferId])

  const fetchEventInfo = async (size: number) => {
    const res = await requestData<{items: ITransferEvent[]; page: number; size: number; total: number} | null>(
      TransferService.getTransferEvents,
      {orderId, transferId: activeTransferId, page: 1, size},
    )
    const transferEvents = res?.items
    eventsTotal.current = res?.total || 3
    if (Array.isArray(transferEvents)) {
      setEvents(transferEvents)
    }
  }

  const fetchHistoryAndEvents = async (size?: number) => {
    const fetchTransferHistory = async () => {
      const curHistory = await requestData<ITransferHistory[] | null>(
        TransferService.getTransferHistory,
        activeTransferId,
      )
      if (Array.isArray(curHistory)) {
        curHistory.reverse()
        setHistory(curHistory)
      }
    }
    const eventSize = size ? size : eventsTotal.current! + 1
    setLoading(true)
    await Promise.all([fetchTransferHistory(), fetchEventInfo(eventSize)])
    setLoading(false)
  }

  const fetchAllEvents = async () => {
    setLoading(true)
    await fetchEventInfo(eventsTotal.current!)
    setLoading(false)
  }

  return (
    <Grid container spacing={1} className={styles.orderTransfers}>
      <Grid item md={12} sm={12} className={styles.warehouseTabsContainer}>
        {warehouseList.map((item) => (
          <div
            key={item.transfer_id}
            onClick={() => setActiveTransferId(item.transfer_id)}
            className={clsx(styles.warehouseTab, activeTransferId === item.transfer_id ? styles.active : "")}
          >
            {item.warehouse_name}
          </div>
        ))}
      </Grid>
      <Grid item md={8} sm={12}>
        <OrderTransferProducts transferId={activeTransferId} />
        <OrderTransferHistory
          transferId={activeTransferId}
          loading={loading}
          setLoading={setLoading}
          history={history}
          fetchHistoryAndEvents={fetchHistoryAndEvents}
          title={warehouseList.find((item) => item.transfer_id === activeTransferId)?.warehouse_name || ""}
        />
      </Grid>
      <Grid item md={4} sm={12}>
        <OrderTransferDetails transferId={activeTransferId} orderId={orderId} />
        <OrderTransferEventInfo
          events={events}
          loading={loading}
          fetchAllEvents={fetchAllEvents}
          total={eventsTotal.current}
        />
      </Grid>
    </Grid>
  )
}

export default OrderTransfers
