import {FC} from "react"

type Props = {
  size?: number
}
const CloseIcon: FC<Props> = ({size}) => {
  return (
    <svg
      width={size ?? "24"}
      height={size ?? "24"}
      style={{minWidth: size ?? "24", minHeight: size ?? "24"}}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 5.25L5.25 18.75"
        stroke="#0B1F35"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.75 18.75L5.25 5.25"
        stroke="#0B1F35"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default CloseIcon
