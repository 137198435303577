import React from "react"
import {OrderShipmentSupplierInfo} from "../../../app/interfaces/salesInterfaces"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {Divider} from "@mui/material"
import Button from "../../../app/components/Button/Button"
import {Login} from "@mui/icons-material"
import {AuthService} from "../../../app/services/AuthService"
import {Alert} from "../../../app/utils/alert"
import requestData from "../../../app/utils/requestData"

type Props = {
  supplierShipmentInfo: OrderShipmentSupplierInfo
  orderSession: string
}

const OrderShipmentSupplierCustomerInfoLayout: React.FC<Props> = ({supplierShipmentInfo, orderSession}) => {
  const redirectToOffline = async (phone: string | undefined) => {
    if (phone) {
      const offlineToken = await requestData<{token: string}>(AuthService.checkTokenForRedirectingOffline, phone)
      if (offlineToken) {
        window.location.href = `${process.env.REACT_APP_OFFLINE_URL}/auth/foreign?token=${offlineToken.token}&redirect=order_adm&order_session=${orderSession}`
      } else Alert.displayErrorMessage("Переход на оффлайн не возможен, так как не вернулся токен!")
    } else {
      Alert.displayErrorMessage("У продавца отсутствует номер телефона!")
    }
  }

  return (
    <OrderLayout>
      {!supplierShipmentInfo.supplier && !supplierShipmentInfo.shipment && (
        <p className="flex min-h-[50px] justify-center items-center opacity-70">
          Информация о продавце и о человеке выдавшем товар отсутствует
        </p>
      )}
      {supplierShipmentInfo.supplier && (
        <>
          <OrderLayoutHeading>
            Информация о продавце
            <Button
              variant="text"
              startIcon={<Login />}
              color="warning"
              onClick={() => redirectToOffline(supplierShipmentInfo.supplier?.phone)}
              disabled={!supplierShipmentInfo.supplier.phone}
            >
              Перейти в оффлайн
            </Button>
          </OrderLayoutHeading>
          <div className={styles.customerInfo}>
            <div className={styles.orderLayoutItem}>
              <p>ФИО</p> <span>{supplierShipmentInfo.supplier.full_name}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Номер телефона</p> <span>{supplierShipmentInfo.supplier.phone}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Электронная почта</p> <span>{supplierShipmentInfo.supplier.email}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Номер склада</p> <span>{supplierShipmentInfo.supplier.warehouse_code_ut}</span>
            </div>
          </div>
          <Divider sx={{marginY: "0.75rem"}} />
        </>
      )}
      {supplierShipmentInfo.shipment && (
        <>
          <OrderLayoutHeading>
            Информация о продавце выдавшем товар
            <Button
              variant="text"
              startIcon={<Login />}
              color="warning"
              onClick={() => redirectToOffline(supplierShipmentInfo.shipment?.phone)}
              disabled={!supplierShipmentInfo.shipment.phone}
            >
              Перейти в оффлайн
            </Button>
          </OrderLayoutHeading>
          <div className={styles.customerInfo}>
            <div className={styles.orderLayoutItem}>
              <p>ФИО</p> <span>{supplierShipmentInfo.shipment.full_name}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Номер телефона</p> <span>{supplierShipmentInfo.shipment.phone}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Электронная почта</p> <span>{supplierShipmentInfo.shipment.email}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Номер склада</p> <span>{supplierShipmentInfo.shipment.warehouse_code_ut}</span>
            </div>
          </div>
        </>
      )}
    </OrderLayout>
  )
}

export default OrderShipmentSupplierCustomerInfoLayout
