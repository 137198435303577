import React, {useState} from "react"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import {OrderService} from "../../../app/interfaces/salesInterfaces"
import styles from "./styles.module.scss"
import {Divider} from "@mui/material"
import {convertNumberInPriceFormat} from "../../../app/utils/helpers"
import Button from "../../../app/components/Button/Button"
import {Alert} from "../../../app/utils/alert"
import {SaleService} from "../../../app/services/SaleService"
import EditIcon from "@mui/icons-material/Edit"
import ChangePhoneNumberModal from "./ChangePhoneNumberModal"
import requestData from "../../../app/utils/requestData"
import OrderBeelineIntergrationInfo from "./OrderBeelineIntergrationInfo"
import useHandlePermissions from "../../../app/hooks/useHandlePermissions"
import {OrderTele2TariffConnect} from "./OrderTele2TariffConnect"
import {InsuranceConnectService} from "./InsuranceConnectService"

type Props = {
  orderServices: OrderService[]
  refetchServices: () => void
  orderHasFinished: boolean
}

const OrderServicesInfo: React.FC<Props> = ({orderServices, refetchServices, orderHasFinished}) => {
  const [isOpenedEditPhoneModal, setIsOpenedEditPhoneModal] = useState(false)
  const [selectedOrderService, setSelectedOrderService] = useState<OrderService | null>(null)
  const [isOpenedJSONIntegrationModal, setIsOpenedJSONIntegrationModal] = useState(false)
  const {hasPermissions} = useHandlePermissions()

  const openJSONModal = (orderService: OrderService) => {
    setSelectedOrderService(orderService)
    setIsOpenedJSONIntegrationModal(true)
  }
  const closeJSONModal = () => {
    setSelectedOrderService(null)
    setIsOpenedJSONIntegrationModal(false)
  }

  const openModal = (orderService: OrderService) => {
    setSelectedOrderService(orderService)
    setIsOpenedEditPhoneModal(true)
  }
  const closeModal = () => {
    setSelectedOrderService(null)
    setIsOpenedEditPhoneModal(false)
  }

  const onClickSockConnection = async (orderServiceId: number, orderId: number) => {
    if (
      await requestData(SaleService.beelineSockConnection, {
        order_service_id: orderServiceId,
      })
    ) {
      Alert.displaySuccessMessage("Запрос успешно выполнен!")
      refetchServices()
    }
  }

  const onClickServiceConnection = async (orderServiceId: number, orderId: number) => {
    if (
      await requestData(SaleService.beelineServiceConnection, {
        order_service_id: orderServiceId,
      })
    ) {
      Alert.displaySuccessMessage("Запрос успешно выполнен!")
      refetchServices()
    }
  }

  return (
    <OrderLayout>
      <OrderLayoutHeading>Купленные услуги</OrderLayoutHeading>
      {orderServices.map((orderService, index) => (
        <div key={index} className={styles.products}>
          <h4 className={styles.heading}>{orderService.service.service_name}</h4>
          {orderService.service.telecom_tariff &&
            orderService.service.telecom_tariff.find(
              (tariff) => tariff.telecom_operator_code === process.env.REACT_APP_BEELINE_CODE,
            ) && (
              <>
                <div className="flex flex-nowrap mt-2">
                  <Button
                    disabled={!hasPermissions("cancel_order") || !orderHasFinished}
                    onClick={() => onClickServiceConnection(orderService.order_service_id, orderService.order_id)}
                    size="small"
                    variant="outlined"
                  >
                    Подключить тариф
                  </Button>
                  <Button
                    disabled={!hasPermissions("cancel_order") || !orderHasFinished}
                    onClick={() => onClickSockConnection(orderService.order_service_id, orderService.order_id)}
                    size="small"
                    variant="outlined"
                    className="!ml-2"
                  >
                    Подключить SOCK
                  </Button>
                  <Button
                    disabled={!hasPermissions("cancel_order") || !orderHasFinished}
                    onClick={() => openJSONModal(orderService)}
                    size="small"
                    variant="outlined"
                    className="!ml-2"
                  >
                    Показать JSON схему интеграции Beeline
                  </Button>
                  <Button
                    disabled={!hasPermissions("cancel_order") || !orderHasFinished}
                    onClick={() => openModal(orderService)}
                    className="!ml-2"
                    color="inherit"
                    variant="outlined"
                    size="small"
                    startIcon={<EditIcon />}
                  >
                    Изменить номер
                  </Button>
                </div>
              </>
            )}
          {orderService.service.telecom_tariff &&
            (orderService.service.telecom_tariff.find((tariff) => tariff.telecom_operator_code === "tele2") ||
              orderService.service.telecom_tariff.find((tariff) => tariff.telecom_operator_code === "altel")) && (
              <OrderTele2TariffConnect
                openChangePhoneModal={openModal}
                orderService={orderService}
                refetchServices={refetchServices}
              />
            )}
          {orderService.service_type &&
            orderService.service_type.service_type_code === "insurance_services" &&
            orderService.order_service_status === 0 && (
              <InsuranceConnectService
                type="lifeInsurance"
                orderServiceId={orderService.order_service_id}
                refetchServices={refetchServices}
                orderHasFinished={orderHasFinished}
              />
            )}
          {orderService.service_type &&
            orderService.service_type.service_type_code === "insurance_services_devices" &&
            orderService.order_service_status === 0 && (
              <InsuranceConnectService
                type="deviceInsurance"
                orderServiceId={orderService.order_service_id}
                refetchServices={refetchServices}
                orderHasFinished={orderHasFinished}
              />
            )}
          <div className={styles.list}>
            <div>
              Количество: <span>{orderService.quantity}</span>
            </div>
            <div>
              Тип услуги: <span>{orderService.service.service_type?.service_type}</span>
            </div>
            <div>
              Код услуги: <span>{orderService.sku}</span>
            </div>
            <div>
              Номер телефона: <span>{orderService.phone || "-"}</span>
            </div>
            <div>
              Партация: <span>{orderService.migrate || "-"}</span>
            </div>
            <div>
              UID Партации: <span>{orderService.migrate_session || "-"}</span>
            </div>
            <div>
              Цена за услугу: <span>{convertNumberInPriceFormat(orderService.price)}тг</span>
            </div>
            {!!orderService.discount && (
              <div>
                Скидка за услугу: <span>{convertNumberInPriceFormat(orderService.discount)}тг</span>
              </div>
            )}
            <div>
              Статус подключения:{" "}
              <span>
                {orderService.order_service_status === 0 && (
                  <div style={{color: "red", fontWeight: "500"}}>Не подключен</div>
                )}
                {orderService.order_service_status === 1 && (
                  <div style={{color: "green", fontWeight: "500"}}>Подключен</div>
                )}
                {orderService.order_service_status === 2 && (
                  <div style={{color: "red", fontWeight: "500"}}>Отменен</div>
                )}
                {orderService.order_service_status === 3 && (
                  <div style={{color: "green", fontWeight: "500"}}>Оплачен</div>
                )}
                {orderService.order_service_status === 4 && (
                  <div style={{color: "orange", fontWeight: "500"}}>Возвращен</div>
                )}
                {orderService.order_service_status === 5 && (
                  <div style={{color: "orange", fontWeight: "500"}}>Подключается</div>
                )}
              </span>
            </div>
            {orderService.keeptalk_url &&
              renderKeeptalkLinks(orderService.keeptalk_url, "Ссылка на keeptalk: ", "Keeptalk")}
            {orderService.keeptalk_used_url &&
              renderKeeptalkLinks(orderService.keeptalk_used_url, "Ссылка на keeptalk б/у: ", "Keeptalk Б/У")}
            {orderService.warranty_url &&
              renderKeeptalkLinks(orderService.warranty_url, " Ссылка на гарантию: ", "Гарантия")}
          </div>
          <Divider />
        </div>
      ))}
      {isOpenedEditPhoneModal && (
        <ChangePhoneNumberModal
          isOpen={isOpenedEditPhoneModal}
          handleClose={closeModal}
          orderServiceId={selectedOrderService?.order_service_id!!}
          prevPhoneNumber={selectedOrderService?.phone || ""}
          refetchServices={refetchServices}
          telecomOperator={
            selectedOrderService?.service.telecom_tariff?.find((item) => !!item.telecom_operator_code)
              ?.telecom_operator_code
          }
          orderHasFinished={orderHasFinished}
        />
      )}
      {isOpenedJSONIntegrationModal && (
        <OrderBeelineIntergrationInfo
          phone={selectedOrderService?.phone}
          isOpened={isOpenedJSONIntegrationModal}
          closeModal={closeJSONModal}
        />
      )}
    </OrderLayout>
  )
}

const renderKeeptalkLinks = (links: string[], label: string, linkLabel: string) => {
  if (typeof links === "string")
    return (
      <div>
        {label}
        <a target="_blank" className={styles.link} href={links}>
          {linkLabel}
        </a>
      </div>
    )
  return links.map((link, i) => (
    <div key={i}>
      {label}
      <a target="_blank" className={styles.link} href={link}>
        {linkLabel}
      </a>
    </div>
  ))
}

export default OrderServicesInfo
