import React from "react"
import Modal from "../../../app/components/Modal/Modal"
import {useForm} from "react-hook-form"
import {BeelineChangePhoneNumber, Tele2ChangePhoneNumber} from "../../../app/interfaces/onlineIntegrationInterfaces"
import {Alert} from "../../../app/utils/alert"
import FormGroup from "../../../app/components/Form/FormGroup"
import Input from "../../../app/components/Input/Input"
import FormHelperMessage from "../../../app/components/Form/FormHelperMessage"
import Button from "../../../app/components/Button/Button"
import SaveAltIcon from "@mui/icons-material/SaveAlt"
import {SaleService} from "../../../app/services/SaleService"
import requestData from "../../../app/utils/requestData"
import useHandlePermissions from "../../../app/hooks/useHandlePermissions"

type Props = {
  isOpen: boolean
  handleClose: () => void
  prevPhoneNumber: string
  orderServiceId: number
  refetchServices: () => void
  telecomOperator: string | undefined
  orderHasFinished: boolean
}

const ChangePhoneNumberModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  prevPhoneNumber,
  orderServiceId,
  refetchServices,
  telecomOperator,
  orderHasFinished,
}) => {
  const {formState, register, handleSubmit, control, setError} = useForm<
    BeelineChangePhoneNumber | Tele2ChangePhoneNumber
  >({
    mode: "onSubmit",
    defaultValues: {
      order_service_id: orderServiceId,
      phone: prevPhoneNumber,
    },
  })
  const {errors, isSubmitting} = formState
  const {hasPermissions} = useHandlePermissions()

  const onSubmit = async (data: BeelineChangePhoneNumber | Tele2ChangePhoneNumber) => {
    const changePhoneRequest = await requestData(
      telecomOperator === "beeline"
        ? SaleService.beelineChangePhoneNumber
        : telecomOperator === "tele2"
        ? SaleService.tele2ChangePhoneNumber
        : SaleService.beelineChangePhoneNumber,
      {
        phone: data.phone.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", ""),
        order_service_id: orderServiceId,
      },
    )
    if (changePhoneRequest) {
      Alert.displaySuccessMessage("Номер успешно изменен!")
      handleClose()
    }
    refetchServices()
  }

  return (
    <Modal title="Изменить номер телефона" open={isOpen} handleClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Input mask="phone" autoFocus label="Номер телефона" control={control} name={{...register("phone")}.name} />
          <FormHelperMessage>{errors.phone?.message}</FormHelperMessage>
        </FormGroup>
        <Button
          type="submit"
          startIcon={<SaveAltIcon />}
          disabled={isSubmitting || !hasPermissions("cancel_order") || !orderHasFinished}
        >
          Изменить
        </Button>
      </form>
    </Modal>
  )
}

export default ChangePhoneNumberModal
