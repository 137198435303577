import React, {useState} from "react"

const useLoading = () => {
  const [isLoading, setIsLoading] = useState(true)

  const setLoading = (isLoadingState: boolean) => {
    setIsLoading(isLoadingState)
  }

  return {isLoading, setLoading}
}

export default useLoading
