import http from "../utils/http"
import {PromiseResponse, PromiseTableResponse} from "../interfaces/http"
import {KaspiGood, KaspiOrderStatus} from "../interfaces/kaspiMarketplaceIntegrationInterfaces"

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/marketplace-integration/api/v1`
// const BASE_URL = `http://localhost:3000/marketplace-integration/api/v1`

const getStatusOfKaspiOrder = (orderId: number): PromiseResponse<KaspiOrderStatus> => {
  return http.get(`${BASE_URL}/kaspi/${orderId}`)
}

const sendOtpKaspi = (orderId: number) => {
  return http.post(`${BASE_URL}/kaspi/send-otp`, {
    order_id: orderId,
  })
}

const approveOtpKaspi = (orderId: number, otpCode: string) => {
  return http.post(`${BASE_URL}/kaspi/confirm-otp`, {
    order_id: orderId,
    otp_code: otpCode,
  })
}

const getKaspiShopGoods = (page: number, size: number, filterData?: object): PromiseTableResponse<KaspiGood> => {
  return http.get(`${BASE_URL}/kaspi-shop/goods`, {
    params: {page, size, ...filterData},
  })
}

const uploadKaspiExceptionGoods = (data: {file: string}): PromiseResponse<boolean> => {
  return http.post(`${BASE_URL}/kaspi-shop/upload-exception-goods`, data)
}

const publishKaspiGood = (kaspiSKU: string, isVisibleProductPage: boolean, isVisibleScoringPage: boolean) => {
  return http.put(`${BASE_URL}/kaspi-shop/goods/publish`, null, {
    params: {
      is_visible_product_page: isVisibleProductPage,
      is_visible_scoring_page: isVisibleScoringPage,
      kaspi_sku: kaspiSKU,
    },
  })
}

const publishAllKaspiGoods = (isActive: boolean, source: "product_page" | "scoring_page") => {
  return http.put(`${BASE_URL}/kaspi-shop/goods/publish-all`, null, {params: {is_active: isActive, source}})
}

export const KaspiMarketplaceIntegrationService = {
  getStatusOfKaspiOrder,
  sendOtpKaspi,
  approveOtpKaspi,
  getKaspiShopGoods,
  uploadKaspiExceptionGoods,
  publishKaspiGood,
  publishAllKaspiGoods,
}
