import React from "react"
import {Avatar, Badge} from "@mui/material"
import styles from "./styles.module.scss"

type Props = {
  fullName: string
}

const SideBarAvatar: React.FC<Props> = ({fullName}) => {
  return (
    <>
      <Badge
        variant="dot"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Avatar
          alt="Avatar"
          sx={{
            width: "55px",
            height: "55px",
          }}
        />
      </Badge>
      <div className={styles.menuFullName}>{fullName}</div>
    </>
  )
}

export default SideBarAvatar
