import http from "../utils/http"
import {ITableResponse, PromiseResponse} from "../interfaces/http"
import {IOrderAction} from "../interfaces/salesInterfaces"

const EVENTLOG_BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/eventlog/api/v1`

const getOrderEvents = (page: number, size: number, filterData?: object) => {
  return http.get(`${EVENTLOG_BASE_URL}/order-event/items`, {params: {page, size, ...filterData}})
}
const getOrderEventsByOrderId = (orderId: number) => {
  return http.get(`${EVENTLOG_BASE_URL}/order-event/items`, {
    params: {
      order_id: orderId,
    },
  })
}

const getOrderActions = (orderId: number): PromiseResponse<ITableResponse<IOrderAction[]>> => {
  return http.get(`${EVENTLOG_BASE_URL}/common-event/items`, {
    params: {
      entity_id: orderId,
      log_type: "LOG_TYPE_ORDER_ADMIN_UPDATE",
    },
  })
}

export const EventLogService = {
  getOrderEvents,
  getOrderEventsByOrderId,
  getOrderActions,
}
