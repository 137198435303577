import http from "../utils/http"
import {PromiseResponse} from "../interfaces/http"
import {OrderStatusFromOneC} from "../interfaces/orderControlnterfaces"
import {Order} from "../interfaces/salesInterfaces"

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/order-control/api/v1`

const getShipmentsMap = (orderId: number) => {
  return http.get(`${BASE_URL}/movement-map/order/${orderId}`, {
    params: {
      order_id: orderId,
    },
  })
}
const postReservationStatus = (orderId: number) => {
  return http.post(`${BASE_URL}/reservation?order_id=${orderId}`, null, {
    params: {
      order_id: orderId,
      validate: true,
    },
  })
}

const getOrderStatusFrom1c = (orderId: number): PromiseResponse<OrderStatusFromOneC> => {
  return http.get(`${BASE_URL}/order/status`, {
    params: {
      order_id: orderId,
    },
  })
}

const getOnlineOrders = (page: number, size: number, filterData?: object): PromiseResponse<Order[]> => {
  return http.get(`${BASE_URL}/order/items`, {
    params: {page, size, ...filterData},
  })
}

const updateStatusOfAppleValidation = (order_id: number, skip_apple_validation: boolean) => {
  return http.post(`${BASE_URL}/admin/skip-apple`, {
    order_id,
    skip_apple_validation,
  })
}

const createTransfer = (orderId: number) => {
  return http.post(`${BASE_URL}/transfer/create-by-order`, null, {
    params: {order_id: orderId},
  })
}

const syncOrderOneC = (orderId: number) => {
  return http.post(`${BASE_URL}/sync-order`, null, {
    params: {order_id: orderId},
  })
}

const getOrderPriceReview = (order_session: string) => {
  return http.get(`${BASE_URL}/order/price-review`, {
    params: {order_session},
  })
}

const changeScoringLimitValidation = (order_id: number, skip_validation_scoring: boolean) => {
  return http.patch(`${BASE_URL}/order/${order_id}/updateScoringSkip`, {skip_validation_scoring})
}

const cancelOrder = (orderSession: string) => {
  return http.post(`${BASE_URL}/order/cancel`, null, {headers: {"order-session": orderSession}})
}

export const OrderControlService = {
  getShipmentsMap,
  postReservationStatus,
  getOrderStatusFrom1c,
  updateStatusOfAppleValidation,
  getOnlineOrders,
  createTransfer,
  syncOrderOneC,
  cancelOrder,
  getOrderPriceReview,
  changeScoringLimitValidation,
}
