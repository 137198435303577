import React, {ReactNode, useState} from "react"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import {OrderPaymentInfo} from "../../../app/interfaces/salesInterfaces"
import styles from "./styles.module.scss"
import clsx from "clsx"
import {convertNumberInPriceFormat} from "../../../app/utils/helpers"
import {Divider} from "@mui/material"
import Button from "../../../app/components/Button/Button"
import {AccountBalance} from "@mui/icons-material"
import {Link} from "react-router-dom"
import {OrderPriceReview} from "../../../app/interfaces/orderControlnterfaces"
import getDiscountSection from "./utils/get-discount-section"

type Props = {
  payment: OrderPaymentInfo
  orderSession: string
  priceReview: OrderPriceReview | null
}

const OrderPaymentLayout: React.FC<Props> = ({payment, orderSession, priceReview}) => {
  const discountsSection = getDiscountSection(priceReview!)
  return (
    <OrderLayout>
      <OrderLayoutHeading>Итог</OrderLayoutHeading>
      {payment.bank_name ? (
        <div className={clsx(styles.orderLayoutItem, styles.long)}>
          <p>Название банка</p>
          <span>{payment.bank_name}</span>
        </div>
      ) : null}
      {payment.price_all_products ? (
        <div className={clsx(styles.orderLayoutItem, styles.long)}>
          <p>Стоимость товаров</p>
          <span>{convertNumberInPriceFormat(payment.price_all_products)} тг</span>
        </div>
      ) : null}
      {payment.price_all_services ? (
        <div className={clsx(styles.orderLayoutItem, styles.long)}>
          <p>Стоимость услуг</p>
          <span>{convertNumberInPriceFormat(payment.price_all_services)} тг</span>
        </div>
      ) : null}
      {!!priceReview?.discount?.total
        ? discountsSection.map((discount, idx) => (
            <div key={idx} className={clsx(styles.orderLayoutItem, styles.long)}>
              <p>{discount.key}</p>
              <span>{discount.value}</span>
            </div>
          ))
        : null}
      {payment.order_payments.map((item, index) => (
        <React.Fragment key={index}>
          <div className={clsx(styles.orderLayoutItem, styles.long)}>
            <p>Способ оплаты</p>
            <span>{item.payment_type_name || "-"}</span>
          </div>
          {item.card_type_name ? (
            <div className={clsx(styles.orderLayoutItem, styles.long)}>
              <p>Тип карты</p>
              <span>{item.card_type_name || "-"}</span>
            </div>
          ) : null}
          {item.broker_loan_id ? (
            <div className={clsx(styles.orderLayoutItem, styles.long)} style={{width: "100%"}}>
              <Link target="_blank" to={`/broker/${item.broker_loan_id}`}>
                <Button
                  startIcon={<AccountBalance />}
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                  variant="text"
                  color="warning"
                >
                  Перейти к заказу в брокере
                </Button>
              </Link>
            </div>
          ) : null}
          <div className={clsx(styles.orderLayoutItem, styles.long)}>
            <p>Стоимость</p>
            <span>{`${convertNumberInPriceFormat(item.amount)} тг` || "-"}</span>
          </div>
          {index + 1 !== payment.order_payments.length && (
            <div className={clsx(styles.orderLayoutItem, styles.long)}>
              <Divider sx={{width: "100%"}} />
            </div>
          )}
        </React.Fragment>
      ))}
      {payment.life_insurance ? (
        <div className={clsx(styles.orderLayoutItem, styles.long)}>
          <p>Стоимость страховки жизни</p>
          <span>{payment.life_insurance} тг</span>
        </div>
      ) : null}
      {payment.contract_percent ? (
        <div className={clsx(styles.orderLayoutItem, styles.long)}>
          <p>Стоимость подключения контракта</p>
          <span>{payment.contract_percent} тг</span>
        </div>
      ) : null}
      {payment.installment ? (
        <div className={clsx(styles.orderLayoutItem, styles.long)}>
          <p>Комиссия за рассрочку</p>
          <span>{payment.installment} тг</span>
        </div>
      ) : null}
      {payment.period ? (
        <div className={clsx(styles.orderLayoutItem, styles.long)}>
          <p>Период</p>
          <span>{payment.period} мес</span>
        </div>
      ) : null}
      <div className={clsx(styles.orderLayoutItem, styles.long)}>
        <p>Итоговая стоимость</p>
        <span>{convertNumberInPriceFormat(payment.total_amount)} тг</span>
      </div>
    </OrderLayout>
  )
}

export default OrderPaymentLayout
