import React from "react"
import {PinInput as ChakraPinInput, PinInputField} from "@chakra-ui/pin-input"
import styles from "./styles.module.scss"
import {Control} from "react-hook-form"
import {Controller} from "react-hook-form"

type Props = {
  codeLength: number
  name: string
  control: Control<any>
}

const PinInput: React.FC<Props> = ({codeLength, name, control}) => {
  return (
    <div className={styles.input}>
      <Controller
        control={control}
        name={name || ""}
        render={({field: {value, onChange, onBlur, ref}}) => (
          <ChakraPinInput autoFocus={true} value={value} type="number" variant="unstyled" onChange={onChange}>
            {[...Array(codeLength || 4)].map((input, index) => (
              <PinInputField className={styles.inputItem} key={index} />
            ))}
          </ChakraPinInput>
        )}
      />
    </div>
  )
}

export default PinInput
