import http from "../utils/http"

export namespace MicrosoftADService {
  const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/auth/api/v1`

  export const getRedirectUrlToMicrosoftServer = (): string => {
    return `${BASE_URL}/auth/open-id/auth?redirect_url=${window.location.origin}/microsoft-auth`
  }

  export const getTokenFromMicrosoftRedirectURL = (config: {params: {code: string}}) => {
    return http.get(`${BASE_URL}/auth/open-id/token`, {params: config.params})
  }

  export const logout = () => {
    return `${BASE_URL}/auth/open-id/logout?redirect_url=${window.location.origin}`
  }
}
