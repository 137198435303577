import React from "react"
import {Breadcrumbs, Link as MuiLink, Typography} from "@mui/material"
import {AppRoutes} from "../../constants/routes"
import {Link} from "react-router-dom"

type Props = {
  links: {
    href?: string
    label: string
  }[]
}

const Breadcrumb: React.FC<Props> = ({links}) => {
  const breadcrumbLinks = links.map((link, index) => {
    if (index + 1 !== links.length) {
      return (
        <MuiLink key={index} color="text.primary">
          <Link to={link.href || ""}>{link.label}</Link>
        </MuiLink>
      )
    } else {
      return <Typography key={index}>{link.label}</Typography>
    }
  })

  if (links.length > 0) document.title = `${links[links.length - 1].label} | FMobile Admin`
  return (
    <Breadcrumbs sx={{marginBottom: "0.5rem"}}>
      <MuiLink color="text.primary">
        <Link to={AppRoutes.home}>Административная панель</Link>
      </MuiLink>
      {breadcrumbLinks}
    </Breadcrumbs>
  )
}

export default Breadcrumb
