import React from "react"
import {UseFormReturn} from "react-hook-form"
import FormGroup from "../../app/components/Form/FormGroup"
import Input from "../../app/components/Input/Input"
import Button from "../../app/components/Button/Button"
import {OtpReceiveModel} from "../../app/interfaces/authInterfaces"
import SmsIcon from "@mui/icons-material/Sms"
import {MicrosoftADService} from "../../app/services/MicrosoftAdService"

type Props = {
  form: UseFormReturn<OtpReceiveModel>
  onSubmit: (data: OtpReceiveModel) => void
}

const LoginOTPReceive: React.FC<Props> = ({form, onSubmit}) => {
  const {formState, register, handleSubmit, control} = form
  const {errors, isSubmitting} = formState

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Input
            label="Номер телефона"
            mask="phone"
            name={{...register("phone")}?.name}
            control={control}
            error={errors.phone?.message}
            autoFocus={true}
            variant="filled"
          />
        </FormGroup>
        <Button fullWidth startIcon={<SmsIcon />} variant="contained" disabled={isSubmitting} type="submit">
          Получить код
        </Button>
        <Button
          href={MicrosoftADService.getRedirectUrlToMicrosoftServer()}
          sx={{mt: 1, mb: 2, width: "100%", backgroundColor: "orange", "&:hover": {background: "orange"}}}
          fullWidth
        >
          <img src="/img/MicrosoftAd.png" style={{width: "24px", height: "24px"}} alt="" />
          Войти через Microsoft Azure AD
        </Button>
      </form>
    </div>
  )
}

export default LoginOTPReceive
