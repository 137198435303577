import React, {SyntheticEvent, useEffect, useRef, useState} from "react"
import styles from "./styles.module.scss"
import {StorageHandler} from "../../app/utils/storageHandler"
import dayjs from "dayjs"

type Props = {
  sendVerificationCodeAgain: () => Promise<boolean>
  useAnotherPhone: () => void
}

const LoginSendOTPAgain: React.FC<Props> = ({sendVerificationCodeAgain, useAnotherPhone}) => {
  const [sendCodeAgainTimeOut, setSendCodeAgainTimeout] = useState<number>(60)
  const [isAllowedToSendCodeAgain, setIsAllowedToSendCodeAgain] = useState<boolean>(false)
  const sendCodeAgainInterval = useRef<any>()

  useEffect(() => {
    const lastTimeWhenCodeWasSent = StorageHandler.getFromStorage("otp.timestamp")
    if (lastTimeWhenCodeWasSent) {
      const timeDifferenceInSeconds = dayjs(lastTimeWhenCodeWasSent).diff(dayjs(), "second")
      sendCodeAgainInterval.current = setInterval(updateTimerForAllowingSendAgain, 1000)
      setSendCodeAgainTimeout(timeDifferenceInSeconds)
    }
    return () => {
      if (sendCodeAgainInterval.current) clearTimeout(sendCodeAgainInterval.current)
    }
  }, [])

  useEffect(() => {
    if (sendCodeAgainTimeOut === 0) {
      setIsAllowedToSendCodeAgain(true)
      StorageHandler.removeFromStorage("otp.timestamp")
      if (sendCodeAgainInterval.current) clearTimeout(sendCodeAgainInterval.current)
    }
  }, [sendCodeAgainTimeOut])

  const updateTimerForAllowingSendAgain = () => setSendCodeAgainTimeout((prevState) => prevState - 1)

  const onClickSendVerificationCodeAgain = async (event: SyntheticEvent) => {
    event.stopPropagation()
    const isOTPSent = await sendVerificationCodeAgain()
    if (isOTPSent) {
      setSendCodeAgainTimeout(60)
      sendCodeAgainInterval.current = setInterval(updateTimerForAllowingSendAgain, 1000)
      setIsAllowedToSendCodeAgain(false)
    }
  }

  return (
    <>
      {!isAllowedToSendCodeAgain && (
        <>
          <p className="mb-1">
            Вы можете отправить код снова через 00:{sendCodeAgainTimeOut.toString().padStart(2, "0")}
          </p>
          <p className="mb-3 text-sm">
            {/*<button*/}
            {/*    onClick={useAnotherPhone}*/}
            {/*    className={styles.loginSendAgain}>*/}
            {/*    Использовать другой номер*/}
            {/*</button>*/}
          </p>
        </>
      )}
      {isAllowedToSendCodeAgain && (
        <button className={styles.loginSendAgain} type="button" onClick={onClickSendVerificationCodeAgain}>
          Отправить код снова
        </button>
      )}
    </>
  )
}

export default LoginSendOTPAgain
