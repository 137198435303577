import React from "react"
import {WarehouseReport} from "../../interfaces/salesInterfaces"
import {Pie} from "react-chartjs-2"

type Props = {
  warehousesStatistics: WarehouseReport[] | null
}

const WarehousesReport: React.FC<Props> = ({warehousesStatistics}) => {
  const data = {
    labels: ["Количество основных складов", "Количество остальных складов"],
    datasets: [
      {
        data: [
          warehousesStatistics && warehousesStatistics?.length > 0 && warehousesStatistics[0].count_main,
          warehousesStatistics && warehousesStatistics?.length > 0 && warehousesStatistics[0].count_others,
        ],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="flex items-center">
      <div style={{width: "400px", height: "400px"}}>
        <Pie options={{maintainAspectRatio: false}} data={data} />
      </div>
      <div className="grow">
        <div className="flex">
          <h3>Общее количество складов:</h3>
          <p className="ml-1 font-semibold">
            {warehousesStatistics && warehousesStatistics?.length > 0 && warehousesStatistics[0].count_all}
          </p>
        </div>
        <div className="flex">
          <h3>Количество основных складов:</h3>
          <p className="ml-1 font-semibold">
            {warehousesStatistics && warehousesStatistics?.length > 0 && warehousesStatistics[0].count_main}
          </p>
        </div>
        <div className="flex">
          <h3>Количество остальных складов:</h3>
          <p className="ml-1 font-semibold">
            {warehousesStatistics && warehousesStatistics?.length > 0 && warehousesStatistics[0].count_others}
          </p>
        </div>
      </div>
    </div>
  )
}

export default WarehousesReport
