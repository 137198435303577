import React, {useEffect, useState} from "react"
import requestData from "../../../app/utils/requestData"
import {JSONTree} from "react-json-tree"
import Modal from "../../../app/components/Modal/Modal"
import {SubscriptionRatesService} from "../../../app/services/SubscriptionRates";

type Props = {
  phone: string | null | undefined
  isOpened: boolean
  closeModal: () => void
}

const OrderBeelineIntegrationInfo: React.FC<Props> = ({phone, isOpened, closeModal}) => {
  const [integrationInfo, setIntegrationInfo] = useState()

  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  }

  useEffect(() => {
    if (isOpened && phone) {
      fetchIntegrationInfo()
    }
  }, [isOpened])

  const fetchIntegrationInfo = async () => {
    const info = await requestData(SubscriptionRatesService.getBeelineIntegrationInfo, phone)
    setIntegrationInfo(info)
  }

  return (
    <div>
      <Modal title="JSON интеграции с Beeline" open={isOpened} handleClose={closeModal}>
        {!phone ? (
          <p className="text-center opacity-70">Номер телефона отсутствует</p>
        ) : (
          <JSONTree theme={theme} invertTheme data={integrationInfo} />
        )}
      </Modal>
    </div>
  )
}

export default OrderBeelineIntegrationInfo
