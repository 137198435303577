import React, {useEffect, useState} from "react"
import {ITransferProduct} from "../../../app/interfaces/transferInterfaces"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {convertNumberInPriceFormat} from "../../../app/utils/helpers"
import requestData from "../../../app/utils/requestData"
import {TransferService} from "../../../app/services/TransferService"

type Props = {
  transferId: number | null
}

const OrderTransferProducts: React.FC<Props> = ({transferId}) => {
  const [productList, setProductList] = useState<ITransferProduct[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (transferId) fetchProducts()
  }, [transferId])

  const fetchProducts = async () => {
    setLoading(true)
    const products = await requestData<ITransferProduct[] | null>(
      TransferService.getTransferAcceptenceProducts,
      transferId,
    )
    if (Array.isArray(products)) {
      setProductList(products)
    }
    setLoading(false)
  }

  if (loading) return <OrderLayout>Данные о товарах загружаются...</OrderLayout>
  if (!productList.length) return <OrderLayout>Товары не найдены</OrderLayout>

  return (
    <OrderLayout className={styles.orderTransferProducts}>
      {productList.map((product, index) => (
        <div key={product.transfer_product_id} className={styles.productItem}>
          <OrderLayoutHeading>Товар {index + 1}</OrderLayoutHeading>
          <div className={styles.productMainInfo}>
            <img
              src={process.env.REACT_APP_BASE_URL + "/" + product.image_path}
              alt="product_image"
              className={styles.productImg}
            />
            <div>{product.name}</div>
          </div>
          <div className={styles.productOtherInfo}>
            <div className={styles.infoItem}>
              <span className={styles.label}>Цена:</span>
              <div className={styles.dashed} />
              <span>{`${convertNumberInPriceFormat(product.price)} тг`}</span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Код товара:</span>
              <div className={styles.dashed} />
              <span>{product.transfer_product_sku}</span>
            </div>
          </div>
        </div>
      ))}
    </OrderLayout>
  )
}

export default OrderTransferProducts
