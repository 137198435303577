import React from "react"
import {OrderCustomerInfo} from "../../../app/interfaces/salesInterfaces"
import styles from "./styles.module.scss"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import {convertNumberInPriceFormat} from "../../../app/utils/helpers"

type Props = {
  customerInfo: OrderCustomerInfo
}

const OrderCustomerInfoLayout: React.FC<Props> = ({customerInfo}) => {
  return (
    <OrderLayout>
      <OrderLayoutHeading>Данные клиента</OrderLayoutHeading>
      <div className={styles.customerInfo}>
        <div className={styles.orderLayoutItem}>
          <p>ФИО</p> <span>{customerInfo.full_name}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Почта</p> <span>{customerInfo.email}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>ИИН</p> <span>{customerInfo.iin}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Номер телефона</p> <span>{customerInfo.phone}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Заработок</p> <span>{convertNumberInPriceFormat(customerInfo.income)} тг</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Доп. заработок</p> <span>{convertNumberInPriceFormat(customerInfo.additional_income)} тг</span>
        </div>
      </div>
    </OrderLayout>
  )
}

export default OrderCustomerInfoLayout
