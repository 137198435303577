import React, {useState} from "react"
import {Alert as MuiAlert, AlertProps, AlertTitle, Collapse} from "@mui/material"

type Props = AlertProps & {
  severity?: "error" | "success" | "warning" | "info"
  title?: string
  closeButton?: boolean
}

const Alert: React.FC<Props> = ({children, severity, title, closeButton, ...otherProps}) => {
  const [open, setOpen] = useState(true)

  const closeAlert = () => {
    setOpen(false)
  }

  return (
    <Collapse in={open}>
      {closeButton && (
        <MuiAlert onClose={closeAlert} severity={severity} {...otherProps}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {children}
        </MuiAlert>
      )}
      {!closeButton && (
        <MuiAlert severity={severity} {...otherProps}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {children}
        </MuiAlert>
      )}
    </Collapse>
  )
}

export default Alert
