import React, {useEffect, useState} from "react"
import Button from "../../../app/components/Button/Button"
import {OrderService} from "../../../app/interfaces/salesInterfaces"
import {useForm} from "react-hook-form"
import requestData from "../../../app/utils/requestData"
import {Alert} from "../../../app/utils/alert"
import {TextField} from "@mui/material"
import {MobileFriendly, Nfc, TapAndPlay, Warning} from "@mui/icons-material"
import {SaleService} from "../../../app/services/SaleService"
import EditIcon from "@mui/icons-material/Edit"
import useHandlePermissions from "../../../app/hooks/useHandlePermissions"
import {SubscriptionRatesService} from "../../../app/services/SubscriptionRates"

type Props = {
  orderService: OrderService
  openChangePhoneModal: (orderService: OrderService) => void
  refetchServices: () => void
}

const OrderTele2TariffConnect: React.FC<Props> = ({orderService, openChangePhoneModal, refetchServices}) => {
  const [showOtpForm, setShowOtpForm] = useState<boolean>(false)
  const {formState, handleSubmit, setValue, reset} = useForm<{code: string}>({
    mode: "onSubmit",
  })
  const {errors, isSubmitting} = formState
  const [isOTPApproved, setIsOTPApproved] = useState<boolean>(false)
  const {hasPermissions} = useHandlePermissions()

  const onClickFinishOrder = async () => {
    if (await requestData(SubscriptionRatesService.sendOtpTele2, orderService.phone)) {
      setShowOtpForm(true)
    } else {
      Alert.displayErrorMessage("Не удалось отправить OTP код на указанный номер!")
    }
  }

  const onSubmitApproveForm = async (data: {code: string}) => {
    if (await requestData(SubscriptionRatesService.approveOtpTele2, orderService.phone, data.code)) {
      setIsOTPApproved(true)
      refetchServices()
    }
  }

  const onClickTariffConnect = async () => {
    if (await requestData(SaleService.connectTele2Tariff, orderService.order_service_id)) {
      Alert.displaySuccessMessage("Тариф Tele 2 / Altel успешно подключен!")
      refetchServices()
    }
  }

  const onClickAsiaPayConnect = async () => {
    if (await requestData(SaleService.doAsiaPayment, orderService.order_service_id)) {
      Alert.displaySuccessMessage("Оплата AsiaPayment успешно подключен!")
      refetchServices()
    }
  }

  useEffect(() => {
    if (!showOtpForm) {
      reset()
    }
  }, [showOtpForm])

  return (
    <div className="mt-2">
      <Button
        onClick={onClickFinishOrder}
        size="small"
        disabled={!(orderService.order_service_status === 0 || orderService.order_service_status === 5) || showOtpForm}
        variant="outlined"
      >
        Отправить код на подключение
      </Button>

      <Button
        disabled={!hasPermissions("cancel_order")}
        onClick={() => openChangePhoneModal(orderService)}
        className="!ml-2"
        color="inherit"
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
      >
        Изменить номер
      </Button>

      <div className="">
        <h3 className="font-medium mb-1 mt-1">
          Для того, чтобы подключить тариф Tele 2 / Altel,{" "}
          <span className="italic">вам нужно получить OTP код клиента</span>
        </h3>
        <p className="opacity-70 mb-1.5">
          Код уже был только что отправлен ему, <span className="italic">введите пожалуйста код ниже</span>
        </p>
        <form onSubmit={handleSubmit(onSubmitApproveForm)} className="mb-2 flex">
          <TextField
            variant="filled"
            label="Код от клиента"
            error={!!errors.code?.message}
            helperText={errors.code?.message}
            onChange={(event) => {
              setValue("code", event.target.value)
            }}
          />
          <Button
            disabled={isSubmitting}
            type="submit"
            startIcon={<MobileFriendly />}
            sx={{marginLeft: "0.5rem"}}
            variant="text"
            size="medium"
            color="success"
          >
            Проверить OTP код
          </Button>
        </form>
        <Button
          type="button"
          disabled={!(orderService.order_service_status === 0 && isOTPApproved)}
          startIcon={<TapAndPlay />}
          color="warning"
          variant="text"
          sx={{
            marginRight: "0.5rem",
          }}
          onClick={onClickTariffConnect}
        >
          Подключить тариф Tele 2 / Altel
        </Button>
        <Button
          type="button"
          disabled={!(orderService.order_service_status === 5 && isOTPApproved)}
          startIcon={<Nfc />}
          color="warning"
          variant="text"
          onClick={onClickAsiaPayConnect}
        >
          Провести платеж AsiaPayment
        </Button>
      </div>
    </div>
  )
}

export {OrderTele2TariffConnect}
