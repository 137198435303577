import React, {useEffect, useState} from "react"
import requestData from "../../../app/utils/requestData"
import {BonusService} from "../../../app/services/BonusService"
import {Divider, Grid} from "@mui/material"
import OrderLayout from "./OrderLayout"
import styles from "./styles.module.scss"
import OrderLayoutHeading from "./OrderLayoutHeading"
import {IOrderBonusInfo} from "../../../app/interfaces/bonusInterfaces"
import {convertNumberInPriceFormat} from "../../../app/utils/helpers"
import Button from "../../../app/components/Button/Button"
import {Alert} from "../../../app/utils/alert"

type Props = {
  orderId: number
}

const OrderBonusesTab: React.FC<Props> = ({orderId}) => {
  const [bonusInfo, setBonusInfo] = useState<IOrderBonusInfo>()

  useEffect(() => {
    fetchBonusInfo()
  }, [])

  const fetchBonusInfo = async () => {
    const bonusInfo = await requestData(BonusService.getBonusInfoByOrder, orderId)
    if (bonusInfo) setBonusInfo(bonusInfo)
  }

  const recalculateBonuses = async () => {
    if (await requestData(BonusService.recalculateBonusesByOrder, orderId)) {
      Alert.displaySuccessMessage("Бонусы были перерасчитаны!")
      fetchBonusInfo()
    }
  }

  if (!bonusInfo) return null
  return (
    <Grid container spacing={1}>
      <Grid item md={8}>
        <OrderLayout>
          <OrderLayoutHeading>Информация о продавце</OrderLayoutHeading>
          <div className={styles.customerInfo}>
            <div className={styles.orderLayoutItem}>
              <p>Контакты продавца</p> <span>{bonusInfo.supplier?.full_name || "-"}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Номер продавца</p> <span>{bonusInfo.supplier?.phone || "-"}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Позиция</p> <span>{bonusInfo.supplier?.position?.name || "-"}</span>
            </div>
          </div>
        </OrderLayout>

        <OrderLayout>
          <OrderLayoutHeading>Информация о бонусах</OrderLayoutHeading>

          {bonusInfo.user_bonuses.map((userBonus) => (
            <div key={userBonus.user_bonus_id} className={styles.customerInfo}>
              <div className={styles.orderLayoutItem}>
                <p>Дата завершения заказа</p> <span>{new Date(userBonus.order_completed_date).toLocaleString()}</span>
              </div>
              <div className={styles.orderLayoutItem}>
                <p>Количество бонусов за заказ</p> <span>{convertNumberInPriceFormat(userBonus.user_bonus_value)}</span>
              </div>
              <div className={styles.orderLayoutItem}>
                <p>Распределение бонусов по товарам/услугам:</p>
              </div>
              {userBonus.user_bonus_model_stocks.map((bonusModelStock) => (
                <div className={styles.products}>
                  <div className={styles.list}>
                    <div>
                      Название товара:{" "}
                      <span>
                        {
                          bonusInfo.order_products.find(
                            (orderProduct) =>
                              orderProduct.model_stock.model_stock_id === bonusModelStock.model_stock_id,
                          )?.model_stock.model_stock_name
                        }
                      </span>
                    </div>
                    <div>
                      Цена: <span>{convertNumberInPriceFormat(bonusModelStock.price)}</span>
                    </div>
                    <div>
                      Количество: <span>{bonusModelStock.quantity}шт</span>
                    </div>
                    <div>
                      <p>Тип оплаты:</p> <span>{bonusModelStock.payment_type_name}</span>
                    </div>
                    <div>
                      <p>Бонусы за продажу:</p>
                      <span>{convertNumberInPriceFormat(bonusModelStock.user_bonus_model_stock_value)}</span>
                    </div>
                  </div>
                  <Divider sx={{marginY: "0.75rem"}} />
                </div>
              ))}
              {userBonus.user_bonus_services.map((bonusService) => (
                <div className={styles.products}>
                  <div className={styles.list}>
                    <div>
                      Название товара:{" "}
                      <span>
                        {
                          bonusInfo.order_services.find(
                            (orderService) => orderService.service.service_id === bonusService.service_id,
                          )?.service.service_name
                        }
                      </span>
                    </div>
                    <div>
                      Цена: <span>{convertNumberInPriceFormat(bonusService.price)}</span>
                    </div>
                    <div>
                      Количество: <span>{bonusService.quantity}шт</span>
                    </div>
                    <div>
                      <p>Тип оплаты:</p> <span>{bonusService.payment_type_name}</span>
                    </div>
                    <div>
                      <p>Бонусы за продажу:</p>
                      <span>{convertNumberInPriceFormat(bonusService.user_bonus_service_value)}</span>
                    </div>
                  </div>
                  <Divider sx={{marginY: "0.75rem"}} />
                </div>
              ))}
            </div>
          ))}
        </OrderLayout>
        <Button variant="contained" color="secondary" fullWidth onClick={recalculateBonuses}>
          Пересчитать
        </Button>
      </Grid>
    </Grid>
  )
}

export default OrderBonusesTab
