import http from "../utils/http"
import {PromiseResponse, PromiseTableResponse} from "../interfaces/http"
import {
  BonusCommandmentWithGroups,
  BonusGroup,
  BonusGroupModelStock,
  CommonBonusCoefficientTypes,
  CreateOrEditBonusGroup,
  IBonusCommandment,
  IBonusGroupService,
  IBrand,
  ICreateBonusCommandment,
  IModel,
  IOfflineCategory,
  IOrderBonusInfo,
  IPaymentType,
  IServiceType,
  NewTemplateUploadForm,
  PaymentWithLoanTerms,
  SellerFineItem,
  TemplateUploadForm,
} from "../interfaces/bonusInterfaces"

export const SALE_BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/sale/api/v1`
export const MOTIVATION_BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/motivation/api/v1`

export namespace BonusService {
  export const getPaymentTypes = (bonusGroup: boolean): PromiseResponse<Array<IPaymentType>> => {
    return http.get(`${MOTIVATION_BASE_URL}/payment-type/all`, {
      params: {bonus_group: bonusGroup},
    })
  }

  export const getPaymentWithLoanTerms = (): PromiseResponse<PaymentWithLoanTerms[]> => {
    return http.get(`${MOTIVATION_BASE_URL}/one-c-payment-method/all-with-loan-terms`)
  }

  export const getBonusCommandmentById = (id: number): PromiseResponse<BonusCommandmentWithGroups> => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-commandment/read?bonus_commandment_id=${id}`)
  }

  export const getCurrentBonusCommandment = (): PromiseResponse<IBonusCommandment | null> => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-commandment/current`)
  }

  export const getFutureDraftBonusCommandments = (
    page: number,
    size: number,
    filterData?: object,
  ): PromiseTableResponse<Array<IBonusCommandment>> => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-commandment/items?bonus_commandment_type=draft`, {
      params: {page, size, ...filterData},
    })
  }

  export const getArchiveBonusCommandments = (
    page: number,
    size: number,
    filterData?: object,
  ): PromiseTableResponse<Array<IBonusCommandment>> => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-commandment/items?bonus_commandment_type=archive`, {
      params: {page, size, ...filterData},
    })
  }

  export const getAllArchiveAndCurrentBonusCommandments = (): PromiseResponse<Array<IBonusCommandment>> => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-commandment/all?bonus_commandment_type=archive_and_current`)
  }

  export const createBonusCommandment = (data: ICreateBonusCommandment): PromiseResponse<IBonusCommandment> => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-commandment`, data)
  }

  export const editBonusCommandment = (
    bonus_commandment_id: number,
    data: ICreateBonusCommandment,
  ): PromiseResponse<IBonusCommandment> => {
    return http.put(`${MOTIVATION_BASE_URL}/bonus-commandment`, data, {
      params: {bonus_commandment_id},
    })
  }

  export const approveBonusCommandment = (bonus_commandment_id: number): PromiseResponse<IBonusCommandment> => {
    return http.put(
      `${MOTIVATION_BASE_URL}/bonus-commandment/approved`,
      {approved: true},
      {params: {bonus_commandment_id}},
    )
  }

  export const deleteBonusCommandment = (bonus_commandment_id: number): PromiseResponse<unknown> => {
    return http.delete(`${MOTIVATION_BASE_URL}/bonus-commandment`, {
      params: {bonus_commandment_id},
    })
  }

  export const createBonusGroup = (data: CreateOrEditBonusGroup): PromiseResponse<BonusGroup> => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-group`, data)
  }

  export const editBonusGroup = (id: number, data: CreateOrEditBonusGroup): PromiseResponse<BonusGroup> => {
    return http.patch(`${MOTIVATION_BASE_URL}/bonus-group?bonus_group_id=${id}`, data)
  }

  export const getBonusGroupById = (id: number): PromiseResponse<BonusGroup> => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-group?bonus_group_id=${id}`)
  }

  export const deleteBonusGroup = (id: number): PromiseResponse<BonusGroup> => {
    return http.delete(`${MOTIVATION_BASE_URL}/bonus-group?bonus_group_id=${id}`)
  }

  export const getModelStocksV2 = (
    commandmentId: number,
    filter: Partial<{
      page: number
      size: number
      model_id: number
      category_id: number
      brand_id: number
      model_sku: string
      model_name: string
    }>,
  ): PromiseTableResponse<Array<BonusGroupModelStock>> => {
    return http.get(`${MOTIVATION_BASE_URL}/model-stock/items?bonus_commandment_id=${commandmentId}`, {params: filter})
  }

  export const getModelStocksInGroup = (
    bonus_group_id: number,
    pagination: {page: number; size: number},
  ): PromiseTableResponse<Array<BonusGroupModelStock>> => {
    return http.get(`${MOTIVATION_BASE_URL}/model-stock/items-by-group`, {
      params: {bonus_group_id, ...pagination},
    })
  }

  export const getAllModelStocksInGroup = (bonus_group_id: number): PromiseResponse<Array<BonusGroupModelStock>> => {
    return http.get(`${MOTIVATION_BASE_URL}/model-stock/all-by-group`, {
      params: {bonus_group_id},
    })
  }

  export const getServicesInGroup = (
    bonus_group_id: number,
    pagination: {page: number; size: number},
  ): PromiseTableResponse<Array<IBonusGroupService>> => {
    return http.get(`${MOTIVATION_BASE_URL}/service/items-by-group`, {
      params: {bonus_group_id, ...pagination},
    })
  }

  export const getAllServicesInGroup = (bonus_group_id: number): PromiseResponse<Array<IBonusGroupService>> => {
    return http.get(`${MOTIVATION_BASE_URL}/service/all-by-group`, {
      params: {bonus_group_id},
    })
  }

  export const getOfflineCategories = (): PromiseResponse<Array<IOfflineCategory>> => {
    return http.get(`${SALE_BASE_URL}/category/all-offline`)
  }

  export const getBrands = (): PromiseResponse<Array<IBrand>> => {
    return http.get(`${SALE_BASE_URL}/brand/all`)
  }

  export const getModels = (): PromiseResponse<Array<IModel>> => {
    return http.get(`${SALE_BASE_URL}/model/all`)
  }

  export const getServicesV2 = (
    bonus_commandment_id: number,
    filter: Partial<{
      page: number
      size: number
      service_name: string
      service_sku: string
      service_type_id: number
    }>,
  ): PromiseTableResponse<Array<IBonusGroupService>> => {
    return http.get(`${MOTIVATION_BASE_URL}/service/items`, {
      params: {bonus_commandment_id, ...filter},
    })
  }

  export const getServiceTypes = (): PromiseResponse<Array<IServiceType>> => {
    return http.get(`${SALE_BASE_URL}/service-type/all`)
  }

  export const uploadBonusFromExcel = (data: {
    bonus_commandment_id: number
    bonus_group_id: number
    file_name: string
    file: string
  }) => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-group-model-stocks/file`, data)
  }

  export const getBonusInfoByOrder = (orderId: number): PromiseResponse<IOrderBonusInfo> => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-by-order`, {
      params: {order_id: orderId},
    })
  }

  export const recalculateBonusesByOrder = (orderId: number): PromiseResponse<IOrderBonusInfo> => {
    return http.post(`${MOTIVATION_BASE_URL}/generate-bonus/recalculation-by-order`, null, {
      params: {order_id: orderId},
    })
  }

  export const uploadUserBonusCoefficientExcel = (data: TemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/user-bonus-coefficient/upload`, data)
  }

  export const uploadWarehouseBonusCoefficientExcel = (data: TemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/warehouse-bonus-coefficient/upload`, data)
  }

  export const uploadPlanBonusCoefficientExcel = (data: TemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/warehouse-bonus-coefficient/plan/upload`, data)
  }

  export const uploadTurnoverBonusCoefficientExcel = (data: TemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/warehouse-bonus-coefficient/turnover_plan/upload`, data)
  }

  export const downloadUserBonusCoefficientTemplate = () => {
    return http.get(`${MOTIVATION_BASE_URL}/user-bonus-coefficient/download`, {
      responseType: "arraybuffer",
    })
  }

  export const downloadCommonTemplate = () => {
    return http.get(`${MOTIVATION_BASE_URL}/warehouse-bonus-coefficient/download`, {responseType: "arraybuffer"})
  }

  export const getUserBonusCoefficientList = (): PromiseResponse<string[]> => {
    return http.get(`${MOTIVATION_BASE_URL}/user-bonus-coefficient/history/list`)
  }

  export const getCommonBonusCoefficientList = (plan: CommonBonusCoefficientTypes): PromiseResponse<string[]> => {
    return http.get(`${MOTIVATION_BASE_URL}/warehouse-bonus-coefficient/history/list`, {
      params: {coef_param: plan},
    })
  }

  export const getCommonCoefficientListItem = (
    month: string,
    plan: CommonBonusCoefficientTypes,
  ): PromiseResponse<any> => {
    return http.get(`${MOTIVATION_BASE_URL}/warehouse-bonus-coefficient/history/list/month`, {
      params: {month, coef_param: plan},
      responseType: "arraybuffer",
    })
  }

  export const getUserBonusCoefficientListItem = (month: string): PromiseResponse<any> => {
    return http.get(`${MOTIVATION_BASE_URL}/user-bonus-coefficient/history/list/month`, {
      params: {month},
      responseType: "arraybuffer",
    })
  }

  export const addModelStocksToBonusGroup = (
    bonus_commandment_id: number,
    bonus_group_id: number,
    model_stock_ids: Array<number>,
  ): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-group-model-stocks/only-add`, {
      bonus_commandment_id,
      bonus_group_id,
      model_stock_ids,
    })
  }

  export const addModelStocksToAdditionalGroup = (
    bonus_commandment_id: number,
    bonus_group_id: number,
    model_stock_ids: Array<number>,
  ): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-group-model-stocks/only-add/additional-group`, {
      bonus_commandment_id,
      bonus_group_id,
      model_stock_ids,
    })
  }

  export const deleteModelStocksFromGroup = (
    bonus_commandment_id: number,
    bonus_group_id: number,
    model_stock_ids: Array<number>,
  ): PromiseResponse<any> => {
    return http.delete(`${MOTIVATION_BASE_URL}/bonus-group-model-stocks/delete-model-stocks`, {
      data: {bonus_commandment_id, bonus_group_id, model_stock_ids},
    })
  }

  export const addServicesToBonusGroup = (
    bonus_commandment_id: number,
    bonus_group_id: number,
    service_ids: Array<number>,
  ): PromiseResponse<null> => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-group-services/only-add`, {
      bonus_commandment_id,
      bonus_group_id,
      service_ids,
    })
  }

  export const addServicesToAdditionalBonusGroup = (
    bonus_commandment_id: number,
    bonus_group_id: number,
    service_ids: Array<number>,
  ): PromiseResponse<null> => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-group-services/only-add/additional-group`, {
      bonus_commandment_id,
      bonus_group_id,
      service_ids,
    })
  }

  export const deleteServicesFromGroup = (
    bonus_commandment_id: number,
    bonus_group_id: number,
    service_ids: Array<number>,
  ): PromiseResponse<any> => {
    return http.delete(`${MOTIVATION_BASE_URL}/bonus-group-services/delete-services`, {
      data: {bonus_commandment_id, bonus_group_id, service_ids},
    })
  }

  export const downloadBonusGroupItemsExcel = (bonus_group_id: number) => {
    return http.get(`${MOTIVATION_BASE_URL}/bonus-group/export`, {
      params: {bonus_group_id},
      responseType: "arraybuffer",
    })
  }

  export const uploadBonusGroupServicesExcel = (data: {
    bonus_commandment_id: number
    bonus_group_id: number
    file_name: string
    file: string
  }) => {
    return http.post(`${MOTIVATION_BASE_URL}/bonus-group-services/file`, data)
  }

  export const downloadSellerFinesTemplate = () => {
    return http.get(`${MOTIVATION_BASE_URL}/fine/download`, {
      responseType: "arraybuffer",
    })
  }

  export const uploadSellerFine = (data: {
    fine_name: string
    fine_period: string
    file_name: string
    file_path: string
    fine_files: [
      {
        file_name: string
        file_path: string
      },
    ]
  }) => {
    return http.post(`${MOTIVATION_BASE_URL}/fine/upload`, data)
  }

  export const getActiveFineItems = (
    page: number,
    size: number,
    filterData?: {
      end_date?: string
      start_date?: string
    },
  ): PromiseTableResponse<SellerFineItem[]> => {
    return http.get(`${MOTIVATION_BASE_URL}/fine/items?fine_type=active`, {
      params: {page, size, ...filterData},
    })
  }

  export const getArchiveFineItems = (
    page: number,
    size: number,
    filterData?: {
      end_date?: string
      start_date?: string
    },
  ): PromiseTableResponse<SellerFineItem[]> => {
    return http.get(`${MOTIVATION_BASE_URL}/fine/items?fine_type=archive`, {
      params: {page, size, ...filterData},
    })
  }

  export const deleteFine = (fine_id: number) => {
    return http.delete(`${MOTIVATION_BASE_URL}/fine`, {
      params: {fine_id},
    })
  }

  export const getDefaultCoefficientsList = (page: number, size: number, filterData?: object) => {
    return http.get(`${MOTIVATION_BASE_URL}/default_coefficient/history-month-periods-all`, {
      params: {page, size, ...filterData},
    })
  }

  export const getLoanCoefficientsList = (page: number, size: number, filterData?: object) => {
    return http.get(`${MOTIVATION_BASE_URL}/loan_coefficient/history-month-periods-all`, {
      params: {page, size, ...filterData},
    })
  }

  export const getMysteryCoefficientsList = (page: number, size: number, filterData?: object) => {
    return http.get(`${MOTIVATION_BASE_URL}/mystery_coefficient/history-month-periods-all`, {
      params: {page, size, ...filterData},
    })
  }

  export const getPlanCoefficientsList = (page: number, size: number, filterData?: object) => {
    return http.get(`${MOTIVATION_BASE_URL}/plan_coefficient/history-month-periods-all`, {
      params: {page, size, ...filterData},
    })
  }

  export const getDefaultCoefficientsSample = () => {
    return http.get<ArrayBuffer>(`${MOTIVATION_BASE_URL}/default_coefficient/sample`, {
      responseType: "arraybuffer",
    })
  }

  export const getLoanCoefficientsSample = () => {
    return http.get<ArrayBuffer>(`${MOTIVATION_BASE_URL}/loan_coefficient/sample`, {
      responseType: "arraybuffer",
    })
  }

  export const getMysteryCoefficientsSample = () => {
    return http.get<ArrayBuffer>(`${MOTIVATION_BASE_URL}/mystery_coefficient/sample`, {
      responseType: "arraybuffer",
    })
  }

  export const getPlanCoefficientsSample = () => {
    return http.get<ArrayBuffer>(`${MOTIVATION_BASE_URL}/plan_coefficient/sample`, {
      responseType: "arraybuffer",
    })
  }

  export const uploadDefaultCoefficientExcel = (data: NewTemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/default_coefficient/upload`, data)
  }

  export const uploadLoanCoefficientExcel = (data: NewTemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/loan_coefficient/upload`, data)
  }

  export const uploadMysteryCoefficientExcel = (data: NewTemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/mystery_coefficient/upload`, data)
  }

  export const uploadPlanCoefficientExcel = (data: NewTemplateUploadForm): PromiseResponse<any> => {
    return http.post(`${MOTIVATION_BASE_URL}/plan_coefficient/upload`, data)
  }

  export const deleteDefaultCoefficientsList = (start_period: string, end_period: string) => {
    return http.delete(`${MOTIVATION_BASE_URL}/default_coefficient/history-month-period`, {
      params: {start_period, end_period},
    })
  }

  export const deleteLoanCoefficientsList = (start_period: string, end_period: string) => {
    return http.delete(`${MOTIVATION_BASE_URL}/loan_coefficient/history-month-period`, {
      params: {start_period, end_period},
    })
  }

  export const deleteMysteryCoefficientsList = (start_period: string, end_period: string) => {
    return http.delete(`${MOTIVATION_BASE_URL}/mystery_coefficient/history-month-period`, {
      params: {start_period, end_period},
    })
  }

  export const deletePlanCoefficientsList = (start_period: string, end_period: string) => {
    return http.delete(`${MOTIVATION_BASE_URL}/plan_coefficient/history-month-period`, {
      params: {start_period, end_period},
    })
  }
}
