import React, {useEffect, useState} from "react"
import {ITransferFullInfo} from "../../../app/interfaces/transferInterfaces"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import requestData from "../../../app/utils/requestData"
import {TransferService} from "../../../app/services/TransferService"
import {Login} from "@mui/icons-material"
import Button from "../../../app/components/Button/Button"
import {AuthService} from "../../../app/services/AuthService"
import {Alert} from "../../../app/utils/alert"
import {OrderControlService} from "../../../app/services/OrderControlService"

type Props = {
  transferId: number | null
  orderId: number
}

const OrderTransferDetails: React.FC<Props> = ({transferId, orderId}) => {
  const [transferDetails, setTransferDetails] = useState<ITransferFullInfo | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (transferId) fetchTransferDetails()
  }, [transferId])

  const fetchTransferDetails = async () => {
    setLoading(true)
    const transferInfo = await requestData<ITransferFullInfo>(TransferService.getTransferFullInfo, transferId)
    if (transferInfo) {
      setTransferDetails(transferInfo)
    }
    setLoading(false)
  }

  const redirectToOffline = async (phone: string | undefined, transferId: number) => {
    if (phone) {
      const offlineToken = await requestData<{token: string}>(AuthService.checkTokenForRedirectingOffline, phone)
      if (offlineToken) {
        window.location.href = `${process.env.REACT_APP_OFFLINE_URL}/auth/foreign?token=${offlineToken.token}&redirect=transfer&transfer_id=${transferId}`
      } else Alert.displayErrorMessage("Переход на оффлайн не возможен, так как не вернулся токен!")
    } else {
      Alert.displayErrorMessage("У продавца отсутствует номер телефона!")
    }
  }

  const redirectToOfflineReceiver = async (phone: string | undefined, transferId: number) => {
    if (phone) {
      const offlineToken = await requestData<{token: string}>(AuthService.checkTokenForRedirectingOffline, phone)
      if (offlineToken) {
        window.location.href = `${process.env.REACT_APP_OFFLINE_URL}/auth/foreign?token=${offlineToken.token}&redirect=transfer_receiver&transfer_id=${transferId}`
      } else Alert.displayErrorMessage("Переход на оффлайн не возможен, так как не вернулся токен!")
    } else {
      Alert.displayErrorMessage("У продавца отсутствует номер телефона!")
    }
  }

  const onCreateNewTransfer = async () => {
    if (await requestData(OrderControlService.createTransfer, orderId)) {
      Alert.displaySuccessMessage("Перемещение создано!")
      fetchTransferDetails()
    }
  }

  return (
    <OrderLayout className={styles.orderTransferDetails}>
      <OrderLayoutHeading>Детали</OrderLayoutHeading>
      <Button sx={{width: "fit-content"}} color="warning" variant="text" onClick={onCreateNewTransfer}>
        Создать перемещение
      </Button>
      {loading && "Данные загружаются..."}
      {!loading && (
        <>
          <div className={styles.mainBlock}>
            <div>Склад отправителя</div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Адрес</span>
              <span>{transferDetails?.from_warehouse.warehouse_address || ""}</span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Телефон</span>
              <span>{transferDetails?.from_warehouse.warehouse_phone || ""}</span>
            </div>
          </div>
          {transferDetails?.sender_user ? (
            <div className={styles.mainBlock}>
              <div>Отправитель</div>
              <Button
                sx={{width: "fit-content"}}
                variant="text"
                startIcon={<Login />}
                color="warning"
                onClick={() => redirectToOffline(transferDetails?.sender_user?.phone, transferDetails!.transfer_id)}
                disabled={!transferDetails?.sender_user.phone}
              >
                Перейти в оффлайн
              </Button>
              <div className={styles.infoItem}>
                <span className={styles.label}>Адрес</span>
                <span>{transferDetails?.sender_user.full_name || ""}</span>
              </div>
              <div className={styles.infoItem}>
                <span className={styles.label}>Телефон</span>
                <span>{`${transferDetails?.sender_user.phone} ${transferDetails?.sender_user.email || "-"}`}</span>
              </div>
            </div>
          ) : null}
          {transferDetails?.recipient_user ? (
            <div className={styles.mainBlock}>
              <div>Принимающий</div>
              <Button
                sx={{width: "fit-content"}}
                variant="text"
                startIcon={<Login />}
                color="warning"
                onClick={() =>
                  redirectToOfflineReceiver(transferDetails?.recipient_user?.phone, transferDetails!.transfer_id)
                }
                disabled={!transferDetails?.recipient_user.phone}
              >
                Перейти в оффлайн
              </Button>
              <div className={styles.infoItem}>
                <span className={styles.label}>Адрес</span>
                <span>{transferDetails?.recipient_user.full_name || ""}</span>
              </div>
              <div className={styles.infoItem}>
                <span className={styles.label}>Телефон</span>
                <span>{`${transferDetails?.recipient_user.phone} ${
                  transferDetails?.recipient_user.email || "-"
                }`}</span>
              </div>
            </div>
          ) : null}

          <div className={styles.additionalBlock}>
            <div>Дополнительная информация</div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Номер заказа</span>
              <span>{transferDetails?.transfer_order_id || ""}</span>
            </div>
          </div>
        </>
      )}
    </OrderLayout>
  )
}

export default OrderTransferDetails
