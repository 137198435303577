import React from "react"
import styles from "./styles.module.scss"

type Props = {}

const OrderLayoutHeading: React.FC<Props> = ({children}) => {
  return <h3 className={styles.heading}>{children}</h3>
}

export default OrderLayoutHeading
