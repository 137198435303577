import React, {useState} from "react"
import styles from "./styles.module.scss"
import LoginOTPReceive from "./LoginOTPReceive"
import LoginOTPVerify from "./LoginOTPVerify"
import {useForm} from "react-hook-form"
import {OtpReceiveModel, OtpVerifyModel} from "../../app/interfaces/authInterfaces"
import {OTPReceiveSchema, OTPVerifySchema} from "../../app/utils/validation"
import {yupResolver} from "@hookform/resolvers/yup"
import {StorageHandler} from "../../app/utils/storageHandler"
import dayjs from "dayjs"
import {AuthService} from "../../app/services/AuthService"
import {getTranslatedMessageByErrorCode, setErrorMessageToField} from "../../app/utils/helpers"
import {useUserContext} from "../../app/context/userContext"

enum signInSections {
  RECEIVE_OTP_SECTION,
  VERIFY_OTP_SECTION,
}

const LoginContainer: React.FC = () => {
  const [activeSection, setActiveSection] = useState(0)
  const [verificationData, setVerificationData] = useState<OtpReceiveModel>({
    phone: "",
  })
  const {userInfo, updateUserInfo} = useUserContext()

  const showVerifyOTPSection = () => setActiveSection(1)

  const showPhoneEnterSection = () => setActiveSection(0)

  const otpReceiveForm = useForm<OtpReceiveModel>({
    mode: "onSubmit",
    resolver: yupResolver(OTPReceiveSchema),
  })

  const otpVerifyForm = useForm<OtpVerifyModel>({
    mode: "onSubmit",
    resolver: yupResolver(OTPVerifySchema),
  })

  const handleReceiveCode = async (data: OtpReceiveModel) => {
    try {
      setVerificationData({
        ...data,
        phone: data.phone.replace("+", "").replace("(", "").replace(")", "").replaceAll(" ", ""),
      })
      let lastTimeWhenCodeWasSent = null
      if (!lastTimeWhenCodeWasSent || dayjs(lastTimeWhenCodeWasSent).diff(dayjs(), "second") < 1) {
        // Если в localStorage не найдено записей с момента послдней отправки кода либо минута с момента последней отправки уже прошла
        setOTPTimeStamp()
        data.phone = data.phone.replace("+", "").replace("(", "").replace(")", "").replaceAll(" ", "")
        const otpReceiveData = await AuthService.receiveOtp(data)
        const otpReceive = await otpReceiveData?.data

        if (otpReceive.status) {
          showVerifyOTPSection()
        } else {
          if (otpReceive.status_code === 1002) setErrorMessageToField(otpReceiveForm.setError, "phone", "Неверный код")
          else if (otpReceive.status_code === 1003)
            setErrorMessageToField(otpReceiveForm.setError, "phone", "Пользователь заблокирован")
          else if (otpReceive.status_code === 1001)
            setErrorMessageToField(otpReceiveForm.setError, "phone", "Неверный номер телефона")
          else if (otpReceive.status_code === 1006)
            setErrorMessageToField(otpReceiveForm.setError, "phone", "У пользователя недостаточно прав")
          else if (otpReceive.status_code === 2001)
            setErrorMessageToField(otpReceiveForm.setError, "phone", "Произошла неизвестная ошибка")
          else if (otpReceive.status_code === 2002)
            setErrorMessageToField(otpReceiveForm.setError, "phone", "Нет прав на выполнение")
          else
            setErrorMessageToField(
              otpReceiveForm.setError,
              "phone",
              getTranslatedMessageByErrorCode(otpReceive.status_code),
            )
          StorageHandler.removeFromStorage("otp.timestamp")
        }
      } else if (lastTimeWhenCodeWasSent && dayjs(lastTimeWhenCodeWasSent).diff(dayjs(), "second") >= 1) {
        // Если минута еще не прошла с последней отправки кода
        setErrorMessageToField(otpReceiveForm.setError, "phone", "Вы совсем недавно отправляли код, подождите минуту")
      }
    } catch (e: any) {
      console.log(e)
      setErrorMessageToField(otpReceiveForm.setError, "phone", "Произошла неизвестная ошибка")
    }
  }

  const handleVerifyCode = async (data: OtpVerifyModel) => {
    try {
      const otpVerifyData = await AuthService.verifyOtp({...data, phone: verificationData.phone})
      const otpVerify = await otpVerifyData?.data
      if (otpVerify.status_code === 1002)
        setErrorMessageToField(otpVerifyForm.setError, "code", "Неверный код либо пользователя не существует")
      else if (otpVerify.status_code === 1001)
        setErrorMessageToField(otpVerifyForm.setError, "code", "Неверный код либо срок действия кода истек")
      else if (otpVerify.status_code === 2001)
        setErrorMessageToField(otpVerifyForm.setError, "code", "Произошла неизвестная ошибка")
      else
        setErrorMessageToField(otpReceiveForm.setError, "code", getTranslatedMessageByErrorCode(otpVerify.status_code))
      if (otpVerify.status === true && otpVerify.message === "Success") {
        await StorageHandler.writeIntoStorage("access_token", otpVerify.result.access_token)
        await StorageHandler.writeIntoStorage("refresh_token", otpVerify.result.refresh_token)
        updateUserInfo(otpVerify.result.user)
      }
    } catch (e: unknown) {
      setErrorMessageToField(otpVerifyForm.setError, "code", "")
    }
  }

  const handleSendVerificationCodeAgain = async () => {
    try {
      setOTPTimeStamp()
      const otpReceiveData = await AuthService.receiveOtp(verificationData)
      const otpReceive = await otpReceiveData?.data
      if (otpReceive.status) {
        return true
      } else {
        if (otpReceive.status_code === 301)
          setErrorMessageToField(otpVerifyForm.setError, "code", "Неверный пользователь")
        else if (otpReceive.status_code === 303)
          setErrorMessageToField(otpVerifyForm.setError, "code", "Пользователь заблокирован")
        else if (otpReceive.status_code === 304)
          setErrorMessageToField(otpVerifyForm.setError, "code", "Неверный номер телефона")
        else if (otpReceive.status_code === 306)
          setErrorMessageToField(otpVerifyForm.setError, "code", "У пользователя недостаточно прав")
        else if (otpReceive.status_code === 401)
          setErrorMessageToField(otpVerifyForm.setError, "code", "Произошла неизвестная ошибка")
        else if (otpReceive.status_code === 405)
          setErrorMessageToField(otpVerifyForm.setError, "code", "Нет прав на выполнение")
        else setErrorMessageToField(otpVerifyForm.setError, "code", "Произошла неизвестная ошибка")
        return false
      }
    } catch (e: any) {
      setErrorMessageToField(otpVerifyForm.setError, "code", "Произошла неизвестная ошибка")
      return false
    }
  }

  const setOTPTimeStamp = () => {
    StorageHandler.writeIntoStorage("otp.timestamp", dayjs().add(60, "s"))
  }

  return (
    <div className={styles.loginForm}>
      {activeSection === signInSections.RECEIVE_OTP_SECTION && (
        <LoginOTPReceive form={otpReceiveForm} onSubmit={handleReceiveCode} />
      )}
      {activeSection === signInSections.VERIFY_OTP_SECTION && (
        <LoginOTPVerify
          form={otpVerifyForm}
          onSubmit={handleVerifyCode}
          sendVerificationCodeAgain={handleSendVerificationCodeAgain}
          goBack={showPhoneEnterSection}
        />
      )}
    </div>
  )
}

export default LoginContainer
