import React from "react"
import {OrderShipmentInfo} from "../../../app/interfaces/salesInterfaces"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import clsx from "clsx"
import {convertNumberInPriceFormat} from "../../../app/utils/helpers"
import {Divider} from "@mui/material"

type Props = {
  shipmentsMap: OrderShipmentInfo
}

const OrderShipmentsMap: React.FC<Props> = ({shipmentsMap}) => {
  return (
    <OrderLayout>
      <OrderLayoutHeading>Перемещения товара в 1С</OrderLayoutHeading>
      {(!shipmentsMap.shipping_warehouses || shipmentsMap.shipping_warehouses?.length === 0) && (
        <div className="opacity-70">Перемещение товара отсуствует</div>
      )}
      {shipmentsMap.shipping_warehouses?.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #B2B1B1",
              marginBottom: "0.5rem",
              fontWeight: "500",
              marginTop: "1rem",
            }}
          >
            <h3 style={{width: "48%"}}>Товары которые есть на складе</h3>
            <h3 style={{width: "48%"}}>Товары которые будут доставлены с других складов</h3>
          </div>
          <div className={clsx(styles.dividedByTwo)}>
            <div className={styles.column}>
              <div>
                {shipmentsMap.collecting_warehouse.products.map((product) => (
                  <div key={product.model_stock_id} className={styles.products}>
                    <h4 className={styles.heading}>{product.model_stock.model_stock_name}</h4>
                    <div className={styles.list}>
                      <div>
                        Количество: <span>{product.quantity}</span>
                      </div>
                      <div>
                        Код товара: <span>{product.model_stock.model_stock_sku}</span>
                      </div>
                      <div>
                        Стоимость: <span>{convertNumberInPriceFormat(product.price)}</span>
                      </div>
                      <div>
                        СКУ: <span>{product.model_stock.model_stock_sku}</span>
                      </div>
                    </div>
                    <Divider />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.column}>
              <div>
                {shipmentsMap.shipping_warehouses?.map((shippingWarehouse) => (
                  <div>
                    <h4>
                      Склад с которого будет доставлен товар:{" "}
                      <strong>
                        {shippingWarehouse.warehouse_name} {shippingWarehouse.city.city_name_ru}
                      </strong>
                    </h4>
                    <div className="accent-red-300">
                      Тип склада: {shippingWarehouse.warehouse_type_new}{" "}
                      {shippingWarehouse.warehouse_type_new === 4 && (
                        <span className="accent-red-500 font-semibold">Склад мерчант</span>
                      )}
                    </div>
                    {shippingWarehouse.products.map((product) => (
                      <div key={product.model_stock_id} className={styles.products}>
                        <h4 className={styles.heading}>{product.model_stock.model_stock_name}</h4>
                        <div className={styles.list}>
                          <div>
                            Количество: <span>{product.quantity}</span>
                          </div>
                          <div>
                            Код товара: <span>{product.model_stock.model_stock_sku}</span>
                          </div>
                          <div>
                            Стоимость: <span>{convertNumberInPriceFormat(product.price)}</span>
                          </div>
                          <div>
                            СКУ: <span>{product.model_stock.model_stock_sku}</span>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </OrderLayout>
  )
}

export default OrderShipmentsMap
