import React from "react"
import {FormHelperText} from "@mui/material"

type Props = {
  error?: boolean
}

const FormHelperMessage: React.FC<Props> = ({error, children}) => {
  return <FormHelperText error={error ?? true}>{children}</FormHelperText>
}

export default FormHelperMessage
