import React, {useState} from "react"
import {AppBar, Box, CssBaseline, IconButton, Icon} from "@mui/material"
import styles from "./styles.module.scss"
import SideBar from "../components/SideBar/SideBar"
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"
import Preloader from "../components/Preloader/Preloader"
import clsx from "clsx"
import {Link} from "react-router-dom"

type Props = {
  title?: string
}

const appBarStyles = (theme: any) => ({
  height: "60px",
  backgroundColor: "var(--secondary-color-75)",
  display: "flex",
  justifyContent: "center",
  zIndex: theme.zIndex.drawer + 1,
})

const MainLayout: React.FC<Props> = ({title, children}) => {
  const [drawerOpen, setDrawerOpen] = useState(true)

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState)
  }

  const closeDrawer = () => setDrawerOpen(false)

  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />
      <AppBar position="fixed" sx={appBarStyles}>
        <div className={styles.appBarContent}>
          <IconButton
            sx={{color: "#fff", marginLeft: "1rem"}}
            className={styles.appBarContentToggleDrawerButton}
            onClick={toggleDrawer}
          >
            <Icon>menu</Icon>
          </IconButton>
          <div className={styles.logo}>
            <Link to="/">
              <img src="/img/logo_white.svg" alt="Logo" />
            </Link>
          </div>
        </div>
      </AppBar>
      <SideBar isDrawerOpen={drawerOpen} closeDrawer={closeDrawer} />
      <main className={clsx(styles.main, {[styles.mainDrawerOpen]: drawerOpen})}>
        {title && <Breadcrumb links={[{label: title}]} />}
        {children}
      </main>
      <ToastContainer />
      <div className="hidden-preloader" id="preloader-container">
        <Preloader />
      </div>
    </Box>
  )
}

export default MainLayout
