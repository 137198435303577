import React, {useEffect, useState} from "react"
import Heading from "../../app/components/Heading/Heading"
import ReportContainer from "../../app/components/ReportContainer"
import {useUserContext} from "../../app/context/userContext"

const IndexPage: React.FC = () => {
  const {userInfo} = useUserContext()
  const [showReports, setShowReports] = useState(false)

  useEffect(() => {
    const rootRole = userInfo?.roles.findIndex((role) => role.role_name === "ROOT")
    if (rootRole !== undefined && rootRole > -1) {
      setShowReports(true)
    }
  }, [userInfo])

  return (
    <div>
      <Heading>Добро пожаловать в административную панель Freedom Mobile!</Heading>
      {showReports && (
        <div>
          <ReportContainer />
        </div>
      )}
    </div>
  )
}

export default IndexPage
