import {createContext, useContext} from "react"
import {User} from "../interfaces/authInterfaces"

export const UserContext = createContext<{
  userInfo: User | null | undefined
  updateUserInfo: (user: User | null) => void
}>({userInfo: null, updateUserInfo: () => {}})

export const useUserContext = () => {
  return useContext(UserContext)
}
