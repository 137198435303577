import * as yup from "yup"

export const BeelineChangePhoneValidationSchema = yup.object().shape({
  phone: yup.string().test({
    name: "phoneValidation",
    test: (value) => {
      if (!value) return false
      else return value.replace(/_/g, "").length === 11
    },
    message: "Неверный формат номера",
  }),
})

export const KaspiOTPValidationSchema = yup.object().shape({
  otp_code: yup.number().required("Данное поле обязательное к заполнению"),
})
