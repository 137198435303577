import React from "react"
import {Grid} from "@mui/material"
import {CityReport} from "../../interfaces/salesInterfaces"
import {Bar} from "react-chartjs-2"

type Props = {
  citiesStatistics: CityReport[] | null
}

const CitiesReport: React.FC<Props> = ({citiesStatistics}) => {
  const data = {
    labels: citiesStatistics ? citiesStatistics.map((city) => city.city_name_ru) : [],
    datasets: [
      {
        label: "Общее количество складов",
        backgroundColor: "red",
        data: citiesStatistics?.map((city) => city.count_all) || [],
      },
      {
        label: "Количество основных",
        backgroundColor: "green",
        data: citiesStatistics?.map((city) => city.count_main) || [],
      },
      {
        label: "Количество остальных",
        backgroundColor: "blue",
        data: citiesStatistics?.map((city) => city.count_other) || [],
      },
    ],
  }

  const modelStockDataByCities = {
    labels: citiesStatistics ? citiesStatistics.map((city) => city.city_name_ru) : [],
    datasets: [
      {
        label: "Общее количество товаров в городе",
        backgroundColor: "orange",
        data: citiesStatistics?.map((city) => city.count_model_stocks) || [],
      },
    ],
  }

  return (
    <div className="mt-2">
      <div style={{width: "100%", height: "500px"}}>
        <Bar
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: "top" as const,
              },
              title: {
                display: true,
                text: "Разделение складов по городам",
              },
            },
          }}
          data={data}
        />
      </div>
      <div style={{width: "100%", height: "500px", marginTop: "1rem"}}>
        <Bar
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: "top" as const,
              },
              title: {
                display: true,
                text: "Разделение товаров по городам",
              },
            },
          }}
          data={modelStockDataByCities}
        />
      </div>
      <Grid container spacing={2}>
        {citiesStatistics &&
          citiesStatistics.map((cityReport, index) => (
            <Grid key={index} item sm={3}>
              <div
                style={{borderWidth: "1px", borderRadius: "4px", padding: "1rem", borderColor: "lightgray"}}
                className="flex flex-col"
              >
                <h2 className="font-semibold text-lg">{cityReport.city_name_ru}</h2>
                <div className="flex justify-between">
                  <h4>Общее количество складов в городе</h4>
                  <span className="font-semibold">{cityReport.count_all}</span>
                </div>
                <div className="flex justify-between">
                  <h4>Количество основных складов</h4>
                  <span className="font-semibold">{cityReport.count_main}</span>
                </div>
                <div className="flex justify-between">
                  <h4>Количество остальных складов</h4>
                  <span className="font-semibold">{cityReport.count_other}</span>
                </div>
                <div className="flex justify-between">
                  <h4>Количество товаров в городе</h4>
                  <span className="font-semibold">{cityReport.count_model_stocks}</span>
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default CitiesReport
