import React, {useEffect, useState} from "react"
import {OrderStatus} from "../../app/interfaces/salesInterfaces"
import requestData from "../../app/utils/requestData"
import {SaleService} from "../../app/services/SaleService"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import {Alert} from "../../app/utils/alert"

type Props = {
  orderSession: string
  isOpen: boolean
  handleClose: () => void
  fetchOrderAgain: () => void
}

const ChangeOrderStatusModal: React.FC<Props> = ({orderSession, isOpen, handleClose, fetchOrderAgain}) => {
  const [orderStatuses, setOrderStatuses] = useState<Array<OrderStatus>>([])
  const [value, setValue] = React.useState<string>("")
  const radioGroupRef = React.useRef<HTMLElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [query, setQuery] = useState<string>("")
  const results = orderStatuses.filter((item) => item.order_status_name.toLowerCase().includes(query.toLowerCase()))
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }
  useEffect(() => {
    if (!isOpen) {
      setValue("")
    }
  }, [isOpen])

  const fetchOrderStatuses = async () => {
    const statuses = await requestData<Array<OrderStatus>>(SaleService.getAllPaymentStatuses)
    if (statuses) {
      setOrderStatuses(statuses)
      setIsLoading(false)
    }
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const changeOrderStatus = async () => {
    if (value) {
      if (await SaleService.changeOrderStatusOfOrder(orderSession, value)) {
        Alert.displaySuccessMessage("Статус заказа успешно изменен!")
        fetchOrderAgain()
        handleClose()
      }
    }
  }

  useEffect(() => {
    if (isOpen) fetchOrderStatuses()
  }, [isOpen])

  return (
    <Dialog
      sx={{"& .MuiDialog-paper": {width: "80%", maxHeight: 435}}}
      TransitionProps={{onEntering: handleEntering}}
      title="Изменение статуса заказа"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>Изменить статус заказа</DialogTitle>
      <DialogContent dividers={true}>
        <TextField label="Поиск" size="small" variant="outlined" value={query} onChange={handleChange} fullWidth />
        {isLoading && "Данные загружаются..."}
        {!isLoading && (
          <RadioGroup
            aria-label="order status change"
            name="order-status-change"
            value={value}
            onChange={handleChangeValue}
          >
            {results.map((orderStatus) => (
              <FormControlLabel
                key={orderStatus.order_status_id}
                control={<Radio />}
                label={orderStatus.order_status_name}
                value={orderStatus.order_status_code}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="info" onClick={changeOrderStatus}>
          Изменить
        </Button>
        <Button autoFocus onClick={handleClose}>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeOrderStatusModal
