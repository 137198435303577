import http from "../utils/http"
import {Channel} from "../interfaces/relationsInterfaces"
import {PromiseResponse} from "../interfaces/http"

// TODO Переместить АПИ в SaleService
const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/sale/api/v1`

const getAllWarehouses = (filterData?: object) => {
  return http.get(`${BASE_URL}/warehouse/all`, {params: {...filterData}})
}

const getWarehouseList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/warehouse/all`, {params: {page, size, ...filterData}})
}

const getWarehouseInfo = (warehouseId: string | undefined, warehouseCode?: number | string | undefined) => {
  return http.get(`${BASE_URL}/warehouse`, {
    params: {
      warehouse_id: warehouseId,
      warehouse_code: warehouseCode,
    },
  })
}

const getChannelList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/channel/items`, {params: {page, size, ...filterData}})
}

const getAllChannels = (): PromiseResponse<Channel[]> => {
  return http.get(`${BASE_URL}/channel/all`)
}

const getChannel = (channelId: number) => {
  return http.get(`${BASE_URL}/channel`, {params: {channel_id: channelId}})
}

const createChannel = (data: Channel) => {
  return http.post(`${BASE_URL}/channel`, data)
}

const updateChannel = (data: Channel) => {
  return http.put(`${BASE_URL}/channel`, data, {params: {channel_id: data.channel_id}})
}

const deleteChannel = (channelId: number) => {
  return http.delete(`${BASE_URL}/channel`, {params: {channel_id: channelId}})
}

export const RelationService = {
  getWarehouseList,
  getWarehouseInfo,
  getChannelList,
  getAllWarehouses,
  getAllChannels,
  getChannel,
  createChannel,
  updateChannel,
  deleteChannel,
}
