import React, {useState} from "react"
import Button from "../../../app/components/Button/Button"
import Modal from "../../../app/components/Modal/Modal"
import {TapAndPlay} from "@mui/icons-material"
import requestData from "../../../app/utils/requestData"
import {SaleService} from "../../../app/services/SaleService"
import {Alert} from "../../../app/utils/alert"

type Props = {
  orderServiceId: number
  type: "deviceInsurance" | "lifeInsurance"
  refetchServices: () => void
  orderHasFinished: boolean
}

const InsuranceConnectService: React.FC<Props> = ({orderServiceId, type, refetchServices, orderHasFinished}) => {
  const [confirmConnectModal, setConfirmConnectModal] = useState(false)

  const connectInsurance = async () => {
    if (type === "deviceInsurance" && (await requestData(SaleService.deviceInsuranceConnect, orderServiceId))) {
      setConfirmConnectModal(false)
      Alert.displaySuccessMessage("Страхование девайса успешно подключено!")
      refetchServices()
    }

    if (type === "lifeInsurance" && (await requestData(SaleService.lifeInsuranceConnect, orderServiceId))) {
      setConfirmConnectModal(false)
      Alert.displaySuccessMessage("Страхование жизни успешно подключено!")
      refetchServices()
    }
  }

  return (
    <div>
      <Button
        disabled={!orderHasFinished}
        color="warning"
        variant="outlined"
        size="small"
        startIcon={<TapAndPlay />}
        onClick={() => setConfirmConnectModal(true)}
      >
        Подключить страхование {type === "deviceInsurance" ? "девайса" : "жизни"}
      </Button>

      <Modal
        title="Подтвердите свое действие"
        open={confirmConnectModal}
        handleClose={() => setConfirmConnectModal(false)}
        action={{
          label: "Подключить",
          onConfirmAction: connectInsurance,
          severity: "success",
        }}
      >
        Вы действительно хотите подключить страхование жизни?
      </Modal>
    </div>
  )
}

export {InsuranceConnectService}
